import * as React from "react";
import {forwardRef} from "react";

import styles from './TextoMascara.module.css';
import TextoMascaraProps from './TextoMascaraProps';
import {InputMask} from "primereact/inputmask";

const TextoMascara = forwardRef((props: TextoMascaraProps, inputRef?: any) => {
    return (
        <>
            <div className={`${styles.espacamento} p-fluid grid`}>
                <div className={props.fullSize ? "field col-12 md:col-12": "field col-12 md:col-4"}>
                    <label className={styles.label} htmlFor={props.id}>{props.label}</label>
                    <span>
                        <InputMask id={props.id} value={props.value ? props.value : ''}
                                   ref={inputRef !== undefined ? inputRef : null}
                                   mask={props.mask} autoClear={props.autoClear}
                                   slotChar={props.slotChar ? props.slotChar : "_"} unmask={props.unmask}
                                   tooltip={props.tooltip ? props.tooltip : undefined}
                                   tooltipOptions={props.tooltipPosition ? {position: props.tooltipPosition} : undefined}
                                   aria-describedby={`${props.id}-message`}
                                   style={{width: `${props.size}`}}
                                   placeholder={props.placeholder}
                                   required={props.required}
                                   maxLength={props.maxLength === undefined ? 255 : props.maxLength}
                                   onBlur={props.onBlurHandler}
                                   onFocus={props.onFocusHandler}
                                   onChange={props.onChangeHandler}/>
                    </span>
                </div>
            </div>
        </>
    );
});

export default TextoMascara;