import * as React from "react";
import ItemEditViewProps from "./ItemEditViewProps";

import styles from './ItemEditView.module.css';
import Botao from "./form/botao/Botao";
import {FilterMatchMode, PrimeIcons} from "primereact/api";
import {Divider} from "primereact/divider";
import {DataTable, DataTableFilterMeta} from "primereact/datatable";
import {Column} from "primereact/column";
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {Entidade} from "../model/Entidade";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {InputIcon} from "primereact/inputicon/inputicon";
import {InputText} from "primereact/inputtext";
import {IconField} from "primereact/iconfield/iconfield";
import {useState} from "react";

function ItemEditView(props: ItemEditViewProps) {

    const adicionarItem = () => {
        props.adicionarFunction();
    }

    const editarItem = (item: Entidade) => {
        props.editarItemFunction(item);
    }

    const removerItem = (item: Entidade) => {
        props.removerItemFunction(item);
    }

    const removerItemConfirm = (rowData: Entidade) => {
        confirmDialog({
            message: 'Tem certeza que deseja remover esse item?',
            header: 'Atenção',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            acceptLabel: "Sim",
            rejectLabel: "Não",
            accept() {
                removerItem(rowData);
            }
        });
    };

    const botoesGridTemplate = (rowData: Entidade) => {
        return (
            <div className="flex align-items-center gap-2">
                <Button icon="pi pi-pencil" className={`${styles.espacamentoBotoes} mr-2`} title={"Editar"}
                        onClick={() => editarItem(rowData)} />

                <Button icon="pi pi-trash" className={`${styles.espacamentoBotoes} mr-2`} title={"Remover"}
                        severity="danger"
                        onClick={() => removerItemConfirm(rowData)} />
            </div>
        );
    };

    // @ts-ignore
    const globalFilters: string[] = props.colunas?.map((item) => item.caminhoAtributo);
    const defaultFilters: DataTableFilterMeta = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    };

    const [filters, setFilters] = useState<DataTableFilterMeta>(defaultFilters);
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters(defaultFilters);
        setGlobalFilterValue('');
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Mostrar todos" outlined onClick={clearFilter} />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Pesquisar..." />
            </div>
        );
    };

    return (
        <>
            <Card id={`${props.id}ItemEditPanel`} title={props.title}>
                <div className={styles.espacamento}>
                    { props.children }
                </div>

                <Botao id={`${props.id}AddButton`} label={"Adicionar"} icon={PrimeIcons.PLUS}
                       onClickHandler={adicionarItem}/>

                <Divider key={`${props.id}DividerItemPanel`} />

                <DataTable value={props.itens} emptyMessage={"Nenhum registro encontrado"}
                           sortMode={"single"}
                           filters={filters} filterDisplay={"menu"}
                           globalFilterFields={globalFilters}
                           header={renderHeader()}
                           tableStyle={{ minWidth: '50rem' }}>
                    <Column body={botoesGridTemplate} header={""} style={{ width: '15%'}} />

                    {props.colunas !== undefined && props.colunas.length > 0 ?
                        (props.colunas.map((item, index) => (
                            <Column key={index} field={item.caminhoAtributo} sortable header={item.tituloColuna} body={item.bodyTemplate}></Column>
                        ))) : (<></>)
                    }

                </DataTable>

            </Card>

            <ConfirmDialog />
        </>
    )
}

export default ItemEditView;