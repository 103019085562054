import Restaurante from "./Restaurante";
import {Entidade} from "./Entidade";
import RestauranteCardapioBebidaItem from "./RestauranteCardapioBebidaItem";
import GrupoBebida from "./GrupoBebida";

export default class RestauranteCardapioBebida extends Entidade {

    nomeCardapio?: string;
    restaurantes?: Restaurante[] = [];
    itens?: RestauranteCardapioBebidaItem[] = [];
    grupos?: GrupoBebida[] = [];

}