import * as React from "react";
import {FocusEvent, forwardRef, useState} from "react";
import CpfCnpjProps from './CpfCnpjProps';
import CpfCnpjUtils from "../../../utils/CpfCnpjUtils";
import Texto from "../texto/Texto";

const CpfCnpj = forwardRef((props: CpfCnpjProps, inputRef?: any) => {
    const [cpfCnpjInvalido, setCpfCnpjInvalido] = useState<boolean>(false);

    const mascaraDocumentosOnBlurHandler = (e: FocusEvent<HTMLInputElement, Element>) => {
        CpfCnpjUtils.formatarCampo(e.target);

        // @ts-ignore
        if (!CpfCnpjUtils.isCpfCnpj(e.target.value)) {
            setCpfCnpjInvalido(true);
        } else {
            setCpfCnpjInvalido(false);
        }

        if (props.onBlurHandler) {
            props.onBlurHandler(e);
        }
    }

    const mascaraDocumentosOnFocusHandler = (e: FocusEvent<HTMLInputElement, Element>) => {
        CpfCnpjUtils.retirarFormatacao(e.target);

        if (props.onFocusHandler) {
            props.onFocusHandler(e);
        }
    }

    return (
        <>
            <Texto id={props.id} value={props.value}
                   ref={inputRef}
                   keyFilter={"pint"}
                   tooltip={props.tooltip}
                   invalid={props.invalid ? props.invalid : cpfCnpjInvalido}
                   tooltipPosition={props.tooltipPosition}
                   label={props.label}
                   placeholder={props.placeholder}
                   required={props.required}
                   maxLength={props.maxLength}
                   onBlurHandler={mascaraDocumentosOnBlurHandler}
                   onFocusHandler={mascaraDocumentosOnFocusHandler}
                   onChangeHandler={props.onChangeHandler}/>
        </>
    );
});

export default CpfCnpj;