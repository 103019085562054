export class ItemEditViewColuna {

    caminhoAtributo?: string;
    tituloColuna?: string;
    bodyTemplate?: (entidade: any) => string;

    private constructor(caminhoAtributo: string, tituloColuna: string, bodyTemplate?: (entidade: any) => string) {
        this.caminhoAtributo = caminhoAtributo;
        this.tituloColuna = tituloColuna;
        this.bodyTemplate = bodyTemplate;
    }

    static of = (caminhoAtributo: string, tituloColuna: string, bodyTemplate?: (entidade: any) => string) => {
        return new ItemEditViewColuna(caminhoAtributo, tituloColuna, bodyTemplate);
    }

}