import * as React from "react";
import {useContext} from "react";
import {useNavigate, useParams} from "react-router-dom";

import styles from './CustomView.module.css';

import {Divider} from 'primereact/divider';
import {Toolbar} from "primereact/toolbar";
import {Button} from "primereact/button";
import {MensagemContext} from "../context/MensagemContext";
import {MensagemContextType} from "../context/MensagemContextType";
import CustomViewProps from "./CustomViewProps";

function CustomView(props: CustomViewProps) {
    const { id } = useParams();
    const navigate = useNavigate();
    const { info, error } = useContext(MensagemContext) as MensagemContextType;

    const voltarInicio = () => {
        navigate(`/${props.id}`);
    }

    const botoes = (
        <>
            {props.botoesAdicionais !== undefined && props.botoesAdicionais.length > 0 &&
                (props.botoesAdicionais.map((botao, botaoIndex) => (
                    botao
                )))}

            <Button className={`mr-2 ${props.mostrarCancelar !== undefined && !props.mostrarCancelar ? styles.ocultarCancelar : ''}`}
                    label={props.cancelarLabel ? props.cancelarLabel : "« Voltar"}
                    title={props.cancelarLabel ? props.cancelarLabel : "« Voltar"} link size={"large"}
                    onClick={voltarInicio} />
        </>
    );

    return (
        <>
            <div className="card">
                <div className={styles.title}>{props.title}</div>

                <Divider/>

                <div className={styles.espacamento}>
                    {props.children}
                </div>


                {props.mostrarBarraBotoes && (
                    <>
                        <Divider/>
                        <Toolbar start={botoes} />
                    </>
                )}
            </div>
        </>
    );
}

export default CustomView;