import React, {useRef, useState} from 'react';

import styles from './Login.module.css';
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import {Autenticacao} from "../../model/Autenticacao";
import {AutenticacaoToken} from "../../model/AutenticacaoToken";
import rest from "../../utils/EnvConfig";
import {Toast} from "primereact/toast";
import {TipoUsuario} from "../../model/TipoUsuario";
import {Card} from "primereact/card";
import Switch from "../../component/form/switch/Switch";

function Login(props: any) {
    const toast = useRef<Toast>(null);
    const [autenticacao, setAutenticacao] = useState<Autenticacao>(new Autenticacao());
    const [loading, setLoading] = useState<boolean>(false);
    const [salvarSenha, setSalvarSenha] = useState<boolean>(false);

    const error = (mensagem: string): void => {
        if (toast.current !== null) {
            toast.current.show({severity:'error', summary: 'Erro', detail: mensagem, life: 5000, style: {color: 'black'}});
        }
    }

    const autenticacaoOnChangeHandler = (e: any) => {
        setAutenticacao({ ...autenticacao, [e.target.id]: e.target.value });
    }

    const autenticarOnKeyDown = async (e: any) => {
        if (e && e.keyCode === 13) {
            await autenticar();
        }
    }

    const switchOnChangeHandler = (e: any) => {
        setSalvarSenha(e.value);
    }

    const autenticar = async () => {
        if (!autenticacao.usuario) {
            error("E-mail é obrigatório");
            return;
        }
        
        if (!autenticacao.senha) {
            error("Senha é obrigatória");
            return;
        }
        
        setLoading(true);

        return fetch(`${rest.server}${rest.autenticacao}/${rest.autenticacaoAutenticar}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(autenticacao),
        })
        .then(async resp => {
            if (resp.ok) {
                return await resp.json() as AutenticacaoToken;
            }

            setLoading(false);

            throw new Error(await resp.text());
        })
        .then((data: AutenticacaoToken) => {
            data.usuarioEmail = autenticacao.usuario;
            data.salvarSenha = salvarSenha;
            props.setToken(data);

            if (data.tipo === TipoUsuario.CLIENTE) {
                window.location.href = "/restaurante";
            } else {
                window.location.href = "/";
            }

            setLoading(false);
        }).catch((err: Error) => {
            error(err.message);
            setLoading(false);
        });
    }

    return (
        <>
            <div className={styles.divMaster}>
                <div className={`${styles.centralizacaoVertical}`}>
                    <center>
                        <Card title={"O que abre hoje"} className={`${styles.formlogin} ${styles.sombra}`}>

                            <p className="m-0">
                                Por favor, entre com seus dados ou clique em <a href={"https://criarconta"} className={styles.linkLogin} target={"_blank"}>Criar conta</a>.
                            </p>

                            <br />
                            <br />

                            <InputText id={"usuario"} placeholder={"E-mail"}
                                       className={styles.espacamento}
                                       onKeyDown={autenticarOnKeyDown}
                                       onChange={autenticacaoOnChangeHandler} />

                            <Password inputId={"senha"} placeholder={"Senha"}
                                      className={styles.espacamento}
                                      feedback={false}
                                      onKeyDown={autenticarOnKeyDown}
                                      onChange={autenticacaoOnChangeHandler} />

                            <br />
                            <br />

                            <Switch checked={salvarSenha} tipoLabel={"login"}
                                    onChangeHandler={switchOnChangeHandler}
                                    label={"Salvar senha"} id={"salvarSenha"} />


                            <br />
                            <Button label={"Entrar"} loading={loading}
                                    className={`${styles.espacamento} ${styles.tamanhoBotaoEntrar}`}
                                    onClick={async () => await autenticar()} />
                            <br />
                            <br />
                            <Button label={"Esqueceu a senha?"} onClick={() => alert("em breve")} link/>

                        </Card>
                    </center>
                </div>
            </div>
            <Toast ref={toast} />
        </>
    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}

export default Login;