import React, { useState, useEffect } from 'react';

import styles from './SemPermissao.module.css';

import { Chart } from 'primereact/chart';

function SemPermissao() {
    return (
        <>
            <center>
                <div className="card">
                    <h1>Sem autorização</h1>
                    <i className="pi pi-ban" style={{ fontSize: '10rem', color: 'indianred' }}></i>
                    <h3>Seu usuário não tem permissão para acessar esse recurso</h3>
                </div>
            </center>
        </>
    );
}

export default SemPermissao;