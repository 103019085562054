import {
    HorarioDiferencialInterval,
    HorarioInterval,
    RestauranteHorarioIntervaloValidator
} from "./RestauranteHorarioIntervaloValidator";
import FormUtils from "../../utils/FormUtils";
import Restaurante from "../../model/Restaurante";
import RestauranteDiaDiferencial from "../../model/RestauranteDiaDiferencial";

export function RestauranteHorarioValidator(entidade: Restaurante, error: (mensagem: string) => void, horarioDiferencialAtual?: RestauranteDiaDiferencial): boolean {
    const errosHorarios: string[] = [];
    const intervalos: HorarioInterval[] = [];
    const intervalosDiferencial: HorarioDiferencialInterval[] = [];

    // validacoes basicas
    // @ts-ignore
    for (const horarioFuncionamento of entidade.horariosFuncionamento) {
        // @ts-ignore
        for (const periodo of horarioFuncionamento.periodos) {
            if (periodo.horaInicio && !periodo.horaFim) {
                errosHorarios.push(`O horário final de ${horarioFuncionamento.diaSemana?.toLowerCase()} está vazio.`);
            }

            if (!periodo.horaInicio && periodo.horaFim) {
                errosHorarios.push(`O horário inicial de ${horarioFuncionamento.diaSemana?.toLowerCase()} está vazio.`);
            }

            if (periodo.horaInicio && periodo.horaFim) {
                const horaInicio = Number(periodo.horaInicio?.split(":")[0]);
                const minutoInicio = Number(periodo.horaInicio?.split(":")[1]);
                const horaFim = Number(periodo.horaFim?.split(":")[0]);
                const minutoFim = Number(periodo.horaFim?.split(":")[1]);

                const dataInicio = new Date(2024, 0, 1, horaInicio, minutoInicio);
                const dataFim = new Date(2024, 0, 1, horaFim, minutoFim);

                if (horaInicio > 23 || minutoInicio > 59) {
                    errosHorarios.push(`O formato da hora início 
                        de ${horarioFuncionamento.diaSemana?.toLowerCase()} deve ser de 00:00 até 23:59.`);
                    break;
                }

                if (horaFim > 23 || minutoFim > 59) {
                    errosHorarios.push(`O formato da hora fim de ${horarioFuncionamento.diaSemana?.toLowerCase()} 
                        deve ser de 00:00 até 23:59.`);
                    break;
                }

                if (dataFim <= dataInicio) {
                    errosHorarios.push(`O período ${periodo.horaInicio} - ${periodo.horaFim} 
                        de ${horarioFuncionamento.diaSemana?.toLowerCase()} está inválido.`);
                }

                // @ts-ignore
                const totalOcorrencias = horarioFuncionamento.periodos
                    .filter(p => p.horaInicio === periodo.horaInicio && p.horaFim === periodo.horaFim).length;

                if (totalOcorrencias > 1) {
                    errosHorarios.push(`O período ${periodo.horaInicio} - ${periodo.horaFim} 
                        de ${horarioFuncionamento.diaSemana?.toLowerCase()} já existe.`);
                    break;
                }
            }
        }
    }

    // validacao horario de funcionamento normal
    // @ts-ignore
    for (const horarioFuncionamento of entidade.horariosFuncionamento) {
        // @ts-ignore
        for (const periodo of horarioFuncionamento.periodos) {
            if (horarioFuncionamento.diaSemana && periodo.horaInicio && periodo.horaFim) {
                intervalos.push({ diaSemana: horarioFuncionamento.diaSemana,
                    inicio: periodo.horaInicio, fim: periodo.horaFim });
            }
        }
    }

    // validacao horarios de funcionamento diferencial
    // @ts-ignore
    for (const horarioFuncionamento of entidade.horariosFuncionamentoDiferencial) {
        if (horarioFuncionamento.data && horarioFuncionamento.horaInicio && horarioFuncionamento.horaFim) {
            intervalosDiferencial.push({ data: horarioFuncionamento.data,
                status: horarioFuncionamento.status,
                inicio: horarioFuncionamento.horaInicio, fim: horarioFuncionamento.horaFim });
        }
    }

    // adiciona o primeiro intervalo diferencial porque quando adiciona o horario, a lista ainda esta vazia
    if (horarioDiferencialAtual) {
        // @ts-ignore
        intervalosDiferencial.push({ data: horarioDiferencialAtual.data,
            status: horarioDiferencialAtual.status,
            // @ts-ignore
            inicio: horarioDiferencialAtual.horaInicio, fim: horarioDiferencialAtual.horaFim });
    }

    // checa os intervalos e monta as mensagens de erro
    const intervaloValidacao = RestauranteHorarioIntervaloValidator(intervalos, intervalosDiferencial);
    if (!intervaloValidacao.valid) {
        if (intervaloValidacao.type === "diaFuncionamento") {
            const erro: string = `O período ${intervaloValidacao.segundoIntervaloConflito?.inicio}
                - ${intervaloValidacao.segundoIntervaloConflito?.fim}
                de ${(intervaloValidacao.segundoIntervaloConflito as HorarioInterval)?.diaSemana} está em conflito.`;
            FormUtils.invalidarComponente(error, erro);
        }

        if (intervaloValidacao.type === "diaFuncionamentoDiferencial") {
            const erro: string = `O período diferencial ${intervaloValidacao.segundoIntervaloConflito?.inicio}
                - ${intervaloValidacao.segundoIntervaloConflito?.fim} está em conflito.`;
            FormUtils.invalidarComponente(error, erro);
        }

        return false;
    }

    if (errosHorarios.length > 0) {
        for (const erro of errosHorarios) {
            FormUtils.invalidarComponente(error, erro);
        }
        return false;
    }

    return true;
}