import React from 'react';
import CustomView from "../../component/CustomView";
import {Divider} from "primereact/divider";

function Atendimento() {
    return (
        <>
            <CustomView id={"atendimento"} title={"Atendimento"}
                        mostrarCancelar={false} mostrarBarraBotoes={false}>
                <a href={"https://atendimento"} target={"_blank"}>Clique aqui</a> para solicitar suporte.

                <Divider />

                <h3>Sobre o sistema</h3>
                <h6>Versão: 1.0.7</h6>
            </CustomView>
        </>
    );
}

export default Atendimento;