
export default abstract class CpfCnpjUtils {

    private constructor() {
    }

    static formatarCampoString = (valor: string | undefined): string => {
        if (valor) {
            if (valor.length <= 11) {
                return CpfCnpjUtils.mascaraCpf(valor);
            } else {
                return CpfCnpjUtils.mascaraCnpj(valor);
            }
        }

        return "";
    }

    static formatarCampo = (campoTexto: HTMLInputElement) => {
        if (campoTexto.value.length <= 11) {
            campoTexto.value = CpfCnpjUtils.mascaraCpf(campoTexto.value);
        } else {
            campoTexto.value = CpfCnpjUtils.mascaraCnpj(campoTexto.value);
        }
    }

    static retirarFormatacao = (campoTexto: HTMLInputElement) => {
        campoTexto.value = campoTexto.value.replace(/(\.|\/|\-)/g,"");
    }

    private static mascaraCpf = (valor: string) => {
        return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4");
    }

    private static mascaraCnpj = (valor: string) => {
        return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5");
    }

    static isCpfCnpj = (cpfCnpj: string): boolean => {
        if (cpfCnpj) {
            cpfCnpj = cpfCnpj.replace(/\./g, '');
            cpfCnpj = cpfCnpj.replace('-', '');
            cpfCnpj = cpfCnpj.replace(/\./g, '');
            cpfCnpj = cpfCnpj.replace('/', '');
        }

        if (cpfCnpj.length == 11) {
            let cpf: string = cpfCnpj.trim();
            let cpfSplit: string[] = cpf.split('');

            let v1: number = 0;
            let v2: number = 0;
            let aux: boolean = false;

            for (let i: number = 1; cpfSplit.length > i; i++) {
                if (cpfSplit[i - 1] != cpfSplit[i]) {
                    aux = true;
                }
            }

            if (!aux) {
                return false;
            }

            for (let i: number = 0, p: number = 10; (cpfSplit.length - 2) > i; i++, p--) {
                v1 += Number(cpfSplit[i]) * p;
            }

            v1 = ((v1 * 10) % 11);

            if (v1 == 10) {
                v1 = 0;
            }

            if (v1 !== Number(cpfSplit[9])) {
                return false;
            }

            for (let i: number = 0, p: number = 11; (cpf.length - 1) > i; i++, p--) {
                v2 += Number(cpfSplit[i]) * p;
            }

            v2 = ((v2 * 10) % 11);

            if (v2 === 10) {
                v2 = 0;
            }

            return v2 === Number(cpfSplit[10]);
        } else if (cpfCnpj.length == 14) {
            let cnpj: string = cpfCnpj.trim();
            let cnpjSplit: string[] = cnpj.split('');

            let v1: number = 0;
            let v2: number = 0;
            let aux: boolean = false;

            for (let i: number = 1; cnpj.length > i; i++) {
                if (Number(cnpjSplit[i - 1]) !== Number(cnpjSplit[i])) {
                    aux = true;
                }
            }

            if (!aux) {
                return false;
            }

            for (let i: number = 0, p1: number = 5, p2: number = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
                if (p1 >= 2) {
                    v1 += Number(cnpjSplit[i]) * p1;
                } else {
                    v1 += Number(cnpjSplit[i]) * p2;
                }
            }

            v1 = (v1 % 11);

            if (v1 < 2) {
                v1 = 0;
            } else {
                v1 = (11 - v1);
            }

            if (v1 !== Number(cnpjSplit[12])) {
                return false;
            }

            for (let i: number = 0, p1: number = 6, p2: number = 14; (cnpj.length - 1) > i; i++, p1--, p2--) {
                if (p1 >= 2) {
                    v2 += Number(cnpjSplit[i]) * p1;
                } else {
                    v2 += Number(cnpjSplit[i]) * p2;
                }
            }

            v2 = (v2 % 11);

            if (v2 < 2) {
                v2 = 0;
            } else {
                v2 = (11 - v2);
            }

            return v2 === Number(cnpjSplit[13]);
        } else {
            return false;
        }
    }

}