import Restaurante from "./Restaurante";
import {Entidade} from "./Entidade";
import RestauranteCardapioComidaItem from "./RestauranteCardapioComidaItem";
import GrupoComida from "./GrupoComida";

export default class RestauranteCardapioComida extends Entidade {

    nomeCardapio?: string;
    restaurantes?: Restaurante[] = [];
    itens?: RestauranteCardapioComidaItem[] = [];
    grupos?: GrupoComida[] = [];

}