import {Entidade} from "./Entidade";
import Restaurante from "./Restaurante";
import Diferencial from "./Diferencial";

export default class RestauranteDiferencial extends Entidade {

    restaurante?: Restaurante;
    diferencial?: Diferencial;
    observacao?: string;
    status: boolean = true;

}