import {Entidade} from "./Entidade";
import Restaurante from "./Restaurante";
import TipoRestaurante from "./TipoRestaurante";

export default class RestauranteTipoRestaurante extends Entidade {

    restaurante?: Restaurante;
    tipoRestaurante?: TipoRestaurante;
    status?: boolean = true;

}