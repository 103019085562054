import React, {createContext, useState, useEffect, useRef} from "react";
import {Toast} from "primereact/toast";
import {MensagemContextType} from "./MensagemContextType";

export const MensagemContext = createContext<MensagemContextType | null>(null);

export const MensagemProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
    const toast = useRef<Toast>(null);

    const error = (mensagem: string): void => {
        if (toast.current !== null) {
            toast.current.show({severity:'error', summary: 'Erro', detail: mensagem, life: 5000, style: {color: 'black'}});
        }
    }

    const info = (mensagem: string): void => {
        if (toast.current !== null) {
            toast.current.show({severity:'info', summary: 'Atenção', detail: mensagem, life: 5000, style: {color: 'black'}});
        }
    }

    return (
        <MensagemContext.Provider value={{info, error}}>
            { children }
            <Toast ref={toast} />
        </MensagemContext.Provider>
    );
}