import React, {useContext, useState} from 'react';
import CustomView from "../../component/CustomView";
import useToken from "../../hook/useToken";
import Senha from "../../component/form/senha/Senha";
import {Perfil} from "../../model/Perfil";
import Botao from "../../component/form/botao/Botao";
import {MensagemContext} from "../../context/MensagemContext";
import {MensagemContextType} from "../../context/MensagemContextType";
import {Ajax} from "../../utils/Ajax";
import rest from "../../utils/EnvConfig";
import styles from "./PerfilEdit.module.css"

function PerfilEdit() {
    const {token} = useToken();
    const {error, info} = useContext(MensagemContext) as MensagemContextType;
    const [perfil, setPerfil] = useState<Perfil>(new Perfil());
    const [loading, setLoading] = useState<boolean>(false);

    const perfilOnChange = (e: any) => {
        setPerfil({...perfil, [e.target.id]: e.target.value});
    }

    const atualizarPerfil = async () => {
        if (!perfil.senhaAtual) {
            error("Informe sua senha atual");
            return;
        }

        if (!perfil.novaSenha) {
            error("Informe uma nova senha");
            return;
        }

        if (perfil.senhaAtual === perfil.novaSenha) {
            error("A senha atual e a nova não podem ser iguais");
            return;
        }

        if (perfil.novaSenha !== perfil.confirmacaoNovaSenha) {
            error("A nova senha não é igual a confirmação da senha");
            return;
        }

        perfil.usuarioId = token.usuarioId;
        setLoading(true);

        await Ajax.of().postRegistroCustom(`${rest.server}${rest.usuario}/${rest.perfil}`, perfil, () => {
            info("Perfil atualizado com sucesso.");
            setPerfil(new Perfil());
            setLoading(false);
        }, (err: Error) => {
            error(err.message);
            setLoading(false);
        });
    }

    const botaoAtualizar = [
        (<Botao id={"atualizarButton"} label={"Atualizar"}
                loading={loading}
                size={"large"} onClickHandler={atualizarPerfil} />)
    ];

    return (
        <>
            <CustomView id={"perfil"} title={"Perfil"} key={"perfilKey"}
                        mostrarBarraBotoes={true}
                        mostrarCancelar={false} botoesAdicionais={botaoAtualizar}>
                <div className={styles.textoExplicativo}>Alterando a senha do usuário: {token.usuarioEmail}</div>

                <br />
                <br />

                <Senha id={"senhaAtual"} label={"* Senha atual"}
                       value={perfil.senhaAtual}
                       placeholder={"Ex: minhaSenhaAtual"}
                       onChangeHandler={perfilOnChange} />

                <Senha id={"novaSenha"} label={"* Nova senha"} value={perfil.novaSenha}
                       placeholder={"Ex: minhaNovaSenhaForte"}
                        onChangeHandler={perfilOnChange} />

                <Senha id={"confirmacaoNovaSenha"} label={"* Confirmar nova senha"} value={perfil.confirmacaoNovaSenha}
                       placeholder={"Ex: minhaNovaSenhaForte"}
                       onChangeHandler={perfilOnChange} />
            </CustomView>
        </>
    );
}

export default PerfilEdit;