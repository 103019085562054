import {Entidade} from "./Entidade";
import Restaurante from "./Restaurante";
import TipoServico from "./TipoServico";

export default class Diaria extends Entidade {

    restaurantes?: Restaurante[] = [];
    tipoServico?: TipoServico;
    data?: Date;
    quantidadeHoras?: number;
    valorPagamento?: number;
    observacao?: string;
    status: boolean = true;

}