
export interface Interval {
    inicio: string;
    fim: string;
}

export interface HorarioInterval extends Interval{
    diaSemana: string;
}

export interface HorarioDiferencialInterval extends Interval {
    data: Date;
    status: boolean;
}

export interface HorarioIntervalError {
    type?: "diaFuncionamento" | "diaFuncionamentoDiferencial";
    primeiroIntervaloConflito?: Interval;
    segundoIntervaloConflito?: Interval;
    valid: boolean;
}

export function RestauranteHorarioIntervaloValidator(intervalos: HorarioInterval[], intervalosDiferencial: HorarioDiferencialInterval[]): HorarioIntervalError {

    function timeToMinutes(time: string): number {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    }

    function isConflict(primeiroIntervalor: Interval, segundoIntervalo: Interval): boolean {
        const start1 = timeToMinutes(primeiroIntervalor.inicio);
        const end1 = timeToMinutes(primeiroIntervalor.fim);
        const start2 = timeToMinutes(segundoIntervalo.inicio);
        const end2 = timeToMinutes(segundoIntervalo.fim);

        return (start1 < end2 && start2 < end1);
    }

    for (let i = 0; i < intervalos.length; i++) {
        for (let j = i + 1; j < intervalos.length; j++) {
            if (isConflict(intervalos[i], intervalos[j]) &&
                intervalos[i].diaSemana === intervalos[j].diaSemana) {
                return {
                    type: "diaFuncionamento",
                    primeiroIntervaloConflito: intervalos[i],
                    segundoIntervaloConflito: intervalos[j],
                    valid: false
                }
            }
        }
    }

    for (let i = 0; i < intervalosDiferencial.length; i++) {
        for (let j = i + 1; j < intervalosDiferencial.length; j++) {
            if (isConflict(intervalosDiferencial[i], intervalosDiferencial[j]) &&
                intervalosDiferencial[i].data.getDate() === intervalosDiferencial[j].data.getDate()) {
                return {
                    type: "diaFuncionamentoDiferencial",
                    primeiroIntervaloConflito: intervalosDiferencial[i],
                    segundoIntervaloConflito: intervalosDiferencial[j],
                    valid: false
                }
            }
        }
    }

    return {valid: true};
}