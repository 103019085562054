import * as React from "react";
import {forwardRef} from "react";

import styles from './Switch.module.css';
import SwitchProps from './SwitchProps';
import {InputSwitch} from "primereact/inputswitch";

const Switch = forwardRef((props: SwitchProps, inputRef?: any) => {
    return (
        <>
            <div className={`${styles.espacamento} p-fluid grid`}>
                {props.tipoLabel === "padrao" && (
                    <div className="field col-12 md:col-4">
                        <label className={styles.label} htmlFor={props.id}>{props.label}</label>
                        <br />
                        <br />
                        <InputSwitch checked={props.checked} id={"status"}
                                         onChange={props.onChangeHandler} />

                    </div>
                )}

                {props.tipoLabel === "login" && (
                    <div className="field grid">
                        <div className={`col ${styles.espacamentoSwitchLogin}`}>
                            <InputSwitch checked={props.checked} id={"status"}
                                         onChange={props.onChangeHandler} />
                        </div>
                        <label className={`col-fixed ${styles.label} ${styles.tamanhoLabelLogin}`} htmlFor={props.id}>{props.label}</label>
                    </div>
                )}

                {/*// TODO Refatorar*/}
                {!props.tipoLabel && (
                    <div className="field col-12 md:col-4">
                        <label className={styles.label} htmlFor={props.id}>{props.label}</label>
                        <br />
                        <br />
                        <InputSwitch checked={props.checked} id={"status"}
                                     onChange={props.onChangeHandler} />

                    </div>
                )}
            </div>
        </>
    );
});

export default Switch;