import ListView from "../../component/ListView";
import {Column} from "primereact/column";
import useToken from "../../hook/useToken";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {TipoUsuario} from "../../model/TipoUsuario";
import rest from "../../utils/EnvConfig";
import moment, {Moment} from "moment/moment";
import Diaria from "../../model/Diaria";
import DinheiroUtils from "../../utils/DinheiroUtils";

function DiariaList() {
    const {token} = useToken();
    const navigate = useNavigate();

    useEffect(() => {
        if (token && token.tipo !== TipoUsuario.ADMINISTRADOR) {
            navigate("/sem-permissao");
            return;
        }
    }, []);

    const statusDiariaTemplate = (diaria: Diaria): string => {
        return diaria.status ? "Ativo" : "Inativo";
    };

    const dataDiariaTemplate = (diaria: Diaria): string => {
        if (diaria.data) {
            const novaData: Moment = moment(diaria.data, "YYYY-MM-DD");
            return novaData.format("DD/MM/YYYY");
        }
        return "";
    };

    const valorDiariaTemplate = (diaria: Diaria) => {
        if (diaria.valorPagamento) {
            return DinheiroUtils.formatToDinheiro(diaria.valorPagamento);
        }
        return "A combinar";
    };

    const restaurantesDiariaTemplate = (diaria: Diaria) => {
        if (diaria.restaurantes) {
            return diaria.restaurantes.map(r => r.nomeEstabelecimento)
                        .reduce((v, acc) => v + ", " + acc);
        }
        return "Nenhum restaurante";
    }

    return (
        <>
            {token && token.tipo === TipoUsuario.ADMINISTRADOR && (
                <ListView title={"Diária"}
                          id={`${rest.diaria}`}>
                    <Column field="restaurantes" header="Restaurantes" sortable body={restaurantesDiariaTemplate} />
                    <Column field="tipoServico.nomeServico" header="Tipo do serviço" sortable />
                    <Column field="data" header="Data" sortable body={dataDiariaTemplate} />
                    <Column field="quantidadeHoras" header="Quantidade de horas" sortable />
                    <Column field="valorPagamento" header="R$ Valor" sortable body={valorDiariaTemplate} />
                    <Column field="status" header="Situação" sortable body={statusDiariaTemplate} />
                </ListView>
            )}
        </>
    );
}

export default DiariaList;