import * as React from "react";
import {forwardRef, SyntheticEvent, useContext, useEffect, useRef, useState} from "react";

import styles from './Grupo.module.css';
import GrupoComidaSearchProps from './GrupoComidaSearchProps';
import GrupoComida from "../../../model/GrupoComida";
import {AutoComplete, AutoCompleteCompleteEvent} from "primereact/autocomplete";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import {confirmDialog} from "primereact/confirmdialog";
import {MensagemContext} from "../../../context/MensagemContext";
import {MensagemContextType} from "../../../context/MensagemContextType";

const GrupoComidaSearch = forwardRef((props: GrupoComidaSearchProps, inputRef?: any) => {
    const {info, error} = useContext(MensagemContext) as MensagemContextType;
    const autoCompleteRef = useRef<any>();
    const [grupos, setGrupos] = useState<GrupoComida[]>([]);
    const [gruposFiltrados, setGruposFiltrados] = useState<GrupoComida[]>([]);

    useEffect(() => {
        if (props.cardapio && props.cardapio.grupos) {
            const grupos = props.cardapio.grupos;
            setGrupos(grupos);
            setGruposFiltrados(grupos);
        }
    }, [props.cardapio]);

    const buscarGrupos = (event: AutoCompleteCompleteEvent) => {
        const pesquisa = event.query;
        if (pesquisa === "") {
            setGruposFiltrados(grupos);
            return;
        }

        setGruposFiltrados(grupos.filter(g => g.nome?.toLowerCase().includes(pesquisa.toLowerCase())));
        setTimeout(() => {
            autoCompleteRef.current?.show();
        }, 500);
    }

    const excluirGrupo = (grupo: GrupoComida) => {
        confirmDialog({
            message: 'Tem certeza que deseja remover esse grupo definitivamente?',
            header: 'Atenção',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            acceptLabel: "Sim",
            rejectLabel: "Não",
            accept() {
                if (props.cardapio) {
                    // @ts-ignore
                    for (const item of props.cardapio.itens) {
                        if (item.grupo?.id === grupo.id && item.grupo?.nome === grupo.nome) {
                            error("Esse grupo não pode ser excluído, pois já está vinculado a um item do cardápio.")
                            return;
                        }
                    }

                    props.cardapio.grupos = props.cardapio?.grupos?.filter(g => g !== grupo);
                    const grupos = props.cardapio.grupos;

                    if (grupos) {
                        setGrupos(grupos);
                        setGruposFiltrados(grupos);
                        setTimeout(() => {
                            autoCompleteRef.current.getInput().blur();
                        }, 200);

                        info("Grupo excluído com sucesso");
                    }
                }
            }
        });
    }

    const itemTemplate = (grupo: GrupoComida) => {
        return (
            <div className="formgrid flex align-items-center">
                <div className="field col-9 md:col-9">
                    {grupo.nome}
                </div>
                <div className="field col-3 md:col-3">
                    <Button icon={PrimeIcons.TRASH} size={"small"}
                            onClick={e => excluirGrupo(grupo)}
                            className={styles.posicaoBotaoExcluirGrupo}
                            severity={"danger"} tooltip={"Excluir grupo"}/>
                </div>
            </div>
        );
    };

    const footerTemplate = () => {
        const novoGrupo = autoCompleteRef.current?.props?.value;
        if (gruposFiltrados.length === 0) {
            return (<div className={styles.espacamentoBotaoCriarNovo}>
                <span>
                    <Button label={"Criar novo grupo"}
                            onClick={e => addNovoGrupo(novoGrupo)}/>
                </span>
            </div>);
        }
    };

    // TODO
    // - problema de performance
    // - quando selecione um item usando a tecla Enter, ele apaga tudo no campo
    // - verificar se tem como, ao clicar no botao Novo grupo, já deixar selecionado

    const addNovoGrupo = (nomeGrupo: string) => {
        const novoGrupo = new GrupoComida();
        novoGrupo.nome = nomeGrupo;

        props.cardapio?.grupos?.push(novoGrupo);

        autoCompleteRef.current?.search({} as SyntheticEvent, nomeGrupo);
    }

    return (
        <>
            <div className={`${styles.espacamento} p-fluid grid`}>
                <div className={props.fullSize ? "field col-12 md:col-12": "field col-12 md:col-4"}>
                    <label className={styles.label} htmlFor={props.id}>{props.label}</label>
                    <span>
                        <AutoComplete id={props.id} value={props.value ? props.value : ''}
                                      suggestions={gruposFiltrados}
                                      completeMethod={buscarGrupos}
                                      field={"nome"}
                                      itemTemplate={itemTemplate}
                                      panelFooterTemplate={footerTemplate}
                                      forceSelection={true}
                                      ref={autoCompleteRef}
                                      tooltip={props.tooltip ? props.tooltip : undefined}
                                      tooltipOptions={props.tooltipPosition ? {position: props.tooltipPosition} : undefined}
                                      aria-describedby={`${props.id}-message`}
                                      invalid={props.invalid}
                                      disabled={props.disabled}
                                      placeholder={props.placeholder}
                                      style={{width: `${props.size}`}}
                                      required={props.required}
                                      maxLength={props.maxLength === undefined ? 255 : props.maxLength}
                                      onBlur={props.onBlurHandler}
                                      onFocus={props.onFocusHandler}
                                      onChange={props.onChangeHandler} />
                    </span>
                </div>
            </div>
        </>
    );
});

export default GrupoComidaSearch;