import {Entidade} from "./Entidade";
import Restaurante from "./Restaurante";
import RestauranteHorarioFuncionamentoPeriodo from "./RestauranteHorarioFuncionamentoPeriodo";

export default class RestauranteHorarioFuncionamento extends Entidade {

    restaurante?: Restaurante;
    diaSemana?: string;
    periodos?: RestauranteHorarioFuncionamentoPeriodo[] = [];

}