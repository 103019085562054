import React, {useContext, useEffect, useState} from "react";

import EditView from "../../component/EditView";
import Texto from "../../component/form/texto/Texto";

import {MensagemContext} from "../../context/MensagemContext";
import {MensagemContextType} from "../../context/MensagemContextType";
import {TooltipPosition} from "../../component/form/TooltipPosition";
import Restaurante from "../../model/Restaurante";
import CpfCnpj from "../../component/form/cpfcnpj/CpfCnpj";
import RestauranteValidator from "./RestauranteValidator";
import CpfCnpjUtils from "../../utils/CpfCnpjUtils";
import Telefone from "../../component/form/telefone/Telefone";
import {Divider} from "primereact/divider";
import {Card} from "primereact/card";
import Combo from "../../component/form/combo/Combo";
import RestauranteTipoRestaurante from "../../model/RestauranteTipoRestaurante";
import TipoRestaurante from "../../model/TipoRestaurante";
import {Ajax} from "../../utils/Ajax";
import Switch from "../../component/form/switch/Switch";
import {PrimeIcons} from "primereact/api";
import styles from "./Restaurante.module.css";
import ItemEditView from "../../component/ItemEditView";
import {ItemEditViewColuna} from "../../component/ItemEditViewColuna";
import RestauranteDiferencial from "../../model/RestauranteDiferencial";
import Diferencial from "../../model/Diferencial";
import RestauranteHorarioFuncionamento from "../../model/RestauranteHorarioFuncionamento";
import {DiaSemana} from "../../model/DiaSemana";
import {Button} from "primereact/button";
import {InputMask} from "primereact/inputmask";
import {Accordion, AccordionTab} from 'primereact/accordion';
import RestauranteHorarioFuncionamentoPeriodo from "../../model/RestauranteHorarioFuncionamentoPeriodo";
import Calendario from "../../component/form/calendario/Calendario";
import RestauranteDiaDiferencial from "../../model/RestauranteDiaDiferencial";
import TextoMascara from "../../component/form/mascara/TextoMascara";
import moment, {Moment} from "moment";
import rest from "../../utils/EnvConfig";
import {Usuario} from "../../model/Usuario";
import useToken from "../../hook/useToken";
import {TipoUsuario} from "../../model/TipoUsuario";
import ItemEditState from "../../component/ItemEditState";
import {RestauranteHorarioValidator} from "./RestauranteHorarioValidator";

function RestauranteEdit() {
    const [itemEditState] = useState<ItemEditState>(new ItemEditState());

    const {token} = useToken();
    const {error} = useContext(MensagemContext) as MensagemContextType;
    const [entidade, setEntidade] = useState<Restaurante>(new Restaurante());
    const [usuarios, setUsuarios] = useState<Usuario[]>([]);
    const [tiposRestaurantes, setTiposRestaurantes] = useState<TipoRestaurante[]>([]);
    const [diferenciais, setDiferenciais] = useState<Diferencial[]>([]);

    const [restauranteTipo, setRestauranteTipo] = useState<RestauranteTipoRestaurante>(new RestauranteTipoRestaurante());
    const [statusRestauranteTipo, setStatusRestauranteTipo] = useState(restauranteTipo.status ? restauranteTipo.status : true);

    const [restauranteDiferencial, setRestauranteDiferencial] = useState<RestauranteDiferencial>(new RestauranteDiferencial());
    const [statusRestauranteDiferencial, setStatusRestauranteDiferencial] = useState(restauranteDiferencial.status ? restauranteDiferencial.status : true);

    const [restauranteDiaDiferencial, setRestauranteDiaDiferencial] = useState<RestauranteDiaDiferencial>(new RestauranteDiaDiferencial());
    const [statusRestauranteDiaDiferencial, setStatusRestauranteDiaDiferencial] = useState(restauranteDiaDiferencial.status ? restauranteDiferencial.status : true);

    // ----
    useEffect(() => {
        Ajax.of()
            .error(error)
            .obterTodos(`${rest.tipoRestaurante}`,
                (data) => setTiposRestaurantes(data));

        Ajax.of()
            .error(error)
            .obterTodos(`${rest.diferencial}`,
                (data) => setDiferenciais(data));

        if (token.tipo === TipoUsuario.ADMINISTRADOR) {
            Ajax.of()
                .error(error)
                .obterTodosCustom(`${rest.usuario}/${rest.usuariosRestaurante}`,
                    (data) => setUsuarios(data));
        }

        if (token && token.tipo === TipoUsuario.CLIENTE) {
            entidade.usuarioId = token.usuarioId;
        }

        montarGridHorarioFuncionamentoNovo();
    }, []);

    // tipos restaurante
    const validarRestauranteTipos = (onSuccess: () => void) => {
        if (!restauranteTipo.tipoRestaurante) {
            error("Tipo do restaurante é obrigatório");
            return;
        }
        onSuccess();
    }

    const adicionarRestauranteTipos = () => {
        validarRestauranteTipos(() => {
            if (entidade.tiposRestaurantes !== undefined) {
                setEntidade({...entidade, tiposRestaurantes: [...entidade.tiposRestaurantes, restauranteTipo]});
                setRestauranteTipo(new RestauranteTipoRestaurante());
            }
            itemEditState.limparState();
        });
    }



    const editarRestauranteTipos = (item: RestauranteTipoRestaurante) => {
        if (itemEditState.state) {
            error(`Já existe um item sendo editado em ${itemEditState.nomeSessaoItem}`);
            return;
        }

        itemEditState.buildItemState("Tipo do restaurante", item);

        setRestauranteTipo(item);
        if (entidade.tiposRestaurantes !== undefined) {
            setEntidade({...entidade, tiposRestaurantes: [...entidade.tiposRestaurantes.filter(t => t !== item)]});
        }
    }

    const removerRestauranteTipos = (item: RestauranteTipoRestaurante) => {
        if (entidade.tiposRestaurantes !== undefined) {
            setEntidade({ ...entidade, tiposRestaurantes: [...entidade.tiposRestaurantes.filter(t => t !== item)] });
        }
    }

    const restauranteTipoOnChangeHandler = (e: any) => {
        setRestauranteTipo({ ...restauranteTipo, [e.target.id]: e.target.value });
    }

    const switchOnChangeHandler = (e: any) => {
        setStatusRestauranteTipo(e.value);
        setRestauranteTipo({ ...restauranteTipo, status: e.value });
    }

    const statusRestauranteTipoTemplate = (tipo: RestauranteTipoRestaurante) => {
        return tipo.status ? "Sim" : "Não";
    };
    // -------


    // diferencial
    const validarRestauranteDiferencias = (onSuccess: () => void) => {
        if (!restauranteDiferencial.diferencial) {
            error("Diferencial é obrigatório");
            return;
        }

        onSuccess();
    }

    const adicionarRestauranteDiferenciais = () => {
        validarRestauranteDiferencias(() => {
            if (entidade.diferenciais !== undefined) {
                setEntidade({...entidade, diferenciais: [...entidade.diferenciais, restauranteDiferencial]});
                setRestauranteDiferencial(new RestauranteDiferencial());

                itemEditState.limparState();
            }
        });
    }

    const editarRestauranteDiferenciais = (item: RestauranteDiferencial) => {
        if (itemEditState.state) {
            error(`Já existe um item sendo editado em ${itemEditState.nomeSessaoItem}`);
            return;
        }

        itemEditState.buildItemState("Diferenciais", item);

        setRestauranteDiferencial(item);
        if (entidade.diferenciais !== undefined) {
            setEntidade({ ...entidade, diferenciais: [...entidade.diferenciais.filter(t => t !== item)] });
        }
    }

    const removerRestauranteDiferenciais = (item: RestauranteDiferencial) => {
        if (entidade.diferenciais !== undefined) {
            setEntidade({ ...entidade, diferenciais: [...entidade.diferenciais.filter(t => t !== item)] });
        }
    }

    const restauranteDiferencialOnChangeHandler = (e: any) => {
        setRestauranteDiferencial({ ...restauranteDiferencial, [e.target.id]: e.target.value });
    }

    const switchDiferencialOnChangeHandler = (e: any) => {
        setStatusRestauranteDiferencial(e.value);
        setRestauranteDiferencial({ ...restauranteDiferencial, status: e.value });
    }

    const statusRestauranteDiferencialTemplate = (tipo: RestauranteDiferencial) => {
        return tipo.status ? "Sim" : "Não";
    };

    // ----



    // dia diferencial
    const validarRestauranteDiaDiferenciais = (onSuccess: () => void) => {
        if (!restauranteDiaDiferencial.data) {
            error("Data do dia diferencial é obrigatório");
            return;
        }

        if (!restauranteDiaDiferencial.horaInicio) {
            error("Hora início do dia diferencial é obrigatório");
            return;
        }

        if (!restauranteDiaDiferencial.horaFim) {
            error("Hora fim do dia diferencial é obrigatório");
            return;
        }

        if (!RestauranteHorarioValidator(entidade, error, restauranteDiaDiferencial)) {
            return;
        }

        onSuccess();
    }

    const adicionarRestauranteDiaDiferenciais = () => {
        validarRestauranteDiaDiferenciais(() => {
            if (entidade.horariosFuncionamentoDiferencial !== undefined) {
                setEntidade({ ...entidade, horariosFuncionamentoDiferencial: [...entidade.horariosFuncionamentoDiferencial, restauranteDiaDiferencial] });
                setRestauranteDiaDiferencial(new RestauranteDiaDiferencial());

                itemEditState.limparState();
            }
        });
    }

    const editarRestauranteDiaDiferenciais = (item: RestauranteDiaDiferencial) => {
        if (itemEditState.state) {
            error(`Já existe um item sendo editado em ${itemEditState.nomeSessaoItem}`);
            return;
        }

        itemEditState.buildItemState("Horário de funcionamento diferencial", item);

        setRestauranteDiaDiferencial(item);
        if (entidade.horariosFuncionamentoDiferencial !== undefined) {
            setEntidade({ ...entidade, horariosFuncionamentoDiferencial: [...entidade.horariosFuncionamentoDiferencial.filter(t => t !== item)] });
        }
    }

    const removerRestauranteDiaDiferenciais = (item: RestauranteDiaDiferencial) => {
        if (entidade.horariosFuncionamentoDiferencial !== undefined) {
            setEntidade({ ...entidade, horariosFuncionamentoDiferencial: [...entidade.horariosFuncionamentoDiferencial.filter(t => t !== item)] });
        }
    }

    const restauranteDiaDiferencialOnChangeHandler = (e: any) => {
        setRestauranteDiaDiferencial({ ...restauranteDiaDiferencial, [e.target.id]: e.target.value });
    }

    const switchDiaDiferencialOnChangeHandler = (e: any) => {
        setStatusRestauranteDiaDiferencial(e.value);
        setRestauranteDiaDiferencial({ ...restauranteDiaDiferencial, status: e.value });
    }

    const statusRestauranteDiaDiferencialTemplate = (tipo: RestauranteDiaDiferencial): string => {
        return tipo.status ? "Aberto" : "Fechado";
    };

    const dataRestauranteDiaDiferencialTemplate = (tipo: RestauranteDiaDiferencial): string => {
        if (tipo.data) {
            const novaData: Moment = moment(tipo.data, "YYYY-MM-DD");
            return novaData.format("DD/MM/YYYY");
        }
        return "";
    };

    // --------

    const usuarioOnChangeHandler = (e: any) => {
        setEntidade({ ...entidade, usuarioId: e.target.value });
    }

    const montarGridHorarioFuncionamentoNovo = () => {
        if (!entidade.id) {
            montarGridHorarioFuncionamento(entidade);
        }
    }

    const montarGridHorarioFuncionamento = (data: Restaurante) => {
        if (data.horariosFuncionamento && data.horariosFuncionamento.length == 0) {
            const diasDaSemana: DiaSemana[] = Object.values(DiaSemana);
            const horarios: RestauranteHorarioFuncionamento[] = [];

            diasDaSemana.forEach((key, value) => {
                const novoHorarioFuncionamento = new RestauranteHorarioFuncionamento();
                novoHorarioFuncionamento.diaSemana = key;
                novoHorarioFuncionamento.periodos = [new RestauranteHorarioFuncionamentoPeriodo()];
                horarios.push(novoHorarioFuncionamento);
            });

            Promise.all(horarios).then(() => {
                setEntidade({...data,
                    horariosFuncionamento: horarios,
                    cpfCnpj: CpfCnpjUtils.formatarCampoString(data.cpfCnpj),
                    // @ts-ignore
                    horariosFuncionamentoDiferencial: [...data.horariosFuncionamentoDiferencial]});
            });
        } else {
            corrigirDataDiaDiferencial(data);
            setEntidade({...data,
                cpfCnpj: CpfCnpjUtils.formatarCampoString(data.cpfCnpj),
                // @ts-ignore
                horariosFuncionamentoDiferencial: [...data.horariosFuncionamentoDiferencial]});
        }
    }

    const corrigirDataDiaDiferencial = (data: Restaurante) => {
        if (data.horariosFuncionamentoDiferencial) {
            data.horariosFuncionamentoDiferencial.forEach(dia => {
                const novaData: Moment = moment(dia.data, "YYYY-MM-DD");
                dia.data = novaData.toDate();
            });
        }
    }

    const editEntidade = (data: Restaurante) => {
        montarGridHorarioFuncionamento(data);
    }

    const entidadeOnChangeHandler = (e: any) => {
        setEntidade({ ...entidade, [e.target.id]: e.target.value });
    }

    const periodoFuncionamentoChangeHandler = (e: any, horarioFuncionamentoIndex: number, periodoIndex: number) => {
        // @ts-ignore
        entidade.horariosFuncionamento[horarioFuncionamentoIndex].periodos[periodoIndex] = {
            // @ts-ignore
            ...entidade.horariosFuncionamento[horarioFuncionamentoIndex].periodos[periodoIndex],
            [e.target.id]: e.target.value
        };

        if (entidade.horariosFuncionamento) {
            setEntidade({...entidade, horariosFuncionamento: [...entidade.horariosFuncionamento]});
        }
    }

    const adicionarPeriodoFuncionamentoHandler = (horarioFuncionamento: RestauranteHorarioFuncionamento,
                                                  horarioFuncionamentoIndex: number) => {
        if (horarioFuncionamento && horarioFuncionamento.periodos) {
            for (const periodo of horarioFuncionamento.periodos) {
                if (!periodo.horaInicio || periodo.horaInicio === "") {
                    error("Informe um hora de início e fim na tabela de dias/horários de funcionamento");
                    return;
                }

                if (!periodo.horaFim || periodo.horaFim === "") {
                    error("Informe um hora de início e fim na tabela de dias/horários de funcionamento");
                    return;
                }
            }
        }

        // @ts-ignore
        entidade.horariosFuncionamento[horarioFuncionamentoIndex].periodos = [
            // @ts-ignore
            ...entidade.horariosFuncionamento[horarioFuncionamentoIndex].periodos, new RestauranteHorarioFuncionamentoPeriodo()
        ];

        if (entidade.horariosFuncionamento) {
            setEntidade({...entidade, horariosFuncionamento: [...entidade.horariosFuncionamento]});
        }
    }

    const removerPeriodoFuncionamentoHandler = (horarioFuncionamento: RestauranteHorarioFuncionamento,
                                                horarioFuncionamentoIndex: number, periodoIndex: number) => {
        // @ts-ignore
        if (entidade.horariosFuncionamento[horarioFuncionamentoIndex].periodos.length > 1) {
            // @ts-ignore
            entidade.horariosFuncionamento[horarioFuncionamentoIndex].periodos.splice(periodoIndex, 1);

            if (entidade.horariosFuncionamento) {
                setEntidade({...entidade, horariosFuncionamento: [...entidade.horariosFuncionamento]});
            }
        } else {
            // @ts-ignore
            entidade.horariosFuncionamento[horarioFuncionamentoIndex].periodos[0].horaInicio = null;
            // @ts-ignore
            entidade.horariosFuncionamento[horarioFuncionamentoIndex].periodos[0].horaFim = null;
            // @ts-ignore
            setEntidade({...entidade, horariosFuncionamento: [...entidade.horariosFuncionamento]});
        }
    }

    const validarHorarioFuncionamentoOnBlurHandler = (horaInicio?: string, horaFim?: string) => {
        if (horaInicio && horaFim) {
            RestauranteHorarioValidator(entidade, error);
        }
    }

    const validar = async (): Promise<boolean> => {
        if (itemEditState.state) {
            error(`Existe um item sendo editado em ${itemEditState.nomeSessaoItem}`);
            return false;
        }

        return await RestauranteValidator(entidade, error);
    };

    // console.log(entidade);

    return (
        <>
            <EditView title={"Restaurante"}
                      id={`${rest.restaurante}`}
                      entidade={entidade}
                      onValidationHandler={validar}
                      entidadeUseStateFunction={editEntidade}>

                <Accordion activeIndex={0}>
                    <AccordionTab header="Informações gerais">

                        {token && token.tipo === TipoUsuario.ADMINISTRADOR && (
                            <Combo values={usuarios} id={"usuarioId"} filter={true}
                                   optionLabel={"nome"} optionValue={"id"}
                                   placeHolder={"Selecione um usuário para o restaurante"}
                                   onChangeHandler={usuarioOnChangeHandler}
                                   label={"Usuário"} value={entidade.usuarioId} />
                        )}

                        <Texto id={"nomeEstabelecimento"} value={entidade.nomeEstabelecimento}
                               label={"* Nome do estabelecimento"} size={"100%"} maxLength={255}
                               tooltip={"Informe o nome do estabelecimento"} tooltipPosition={TooltipPosition.TOP}
                               onChangeHandler={entidadeOnChangeHandler}/>

                        <Texto id={"razaoSocial"} value={entidade.razaoSocial}
                               label={"* Razão social"} size={"100%"} maxLength={255}
                               tooltip={"Informe a razão social"} tooltipPosition={TooltipPosition.TOP}
                               onChangeHandler={entidadeOnChangeHandler}/>

                        <CpfCnpj id={"cpfCnpj"} value={entidade.cpfCnpj} unmask={true}
                                 label={"* CPF/CNPJ"} size={"100%"} maxLength={14}
                                 tooltip={"Informe o CPF ou CNPJ"} tooltipPosition={TooltipPosition.TOP}
                                 onChangeHandler={entidadeOnChangeHandler}/>

                        <Texto id={"enderecoCompleto"} value={entidade.enderecoCompleto}
                               label={"* Endereço completo"} size={"100%"} maxLength={255}
                               placeholder={"Ex: Av. Brasil, 123, Centro, Maringá, PR"}
                               tooltip={"Informe o endereço completo"} tooltipPosition={TooltipPosition.TOP}
                               onChangeHandler={entidadeOnChangeHandler}/>

                        <Telefone id={"telefone"} label={"Telefone"} value={entidade.telefone}
                                  size={"100%"}
                                  tooltip={"Informe um telefone/celular"} tooltipPosition={TooltipPosition.TOP}
                                  onChangeHandler={entidadeOnChangeHandler}/>

                        <Divider/>

                        <div className="formgrid grid">
                            <div className="field col">
                                <Texto id={"instagram"} value={entidade.instagram}
                                       label={"Instagram"} size={"100%"} maxLength={200}
                                       fullSize={true}
                                       placeholder={"Ex: instagram.com/meurestaurante"}
                                       tooltip={"Informe o endereço completo do seu Instagram"} tooltipPosition={TooltipPosition.TOP}
                                       onChangeHandler={entidadeOnChangeHandler}/>
                            </div>
                            <div className="field col">
                                <Texto id={"facebook"} value={entidade.facebook}
                                       fullSize={true}
                                       placeholder={"Ex: facebook.com/meurestaurante"}
                                       label={"Facebook"} size={"100%"} maxLength={200}
                                       tooltip={"Informe o endereço completo do seu Facebook"} tooltipPosition={TooltipPosition.TOP}
                                       onChangeHandler={entidadeOnChangeHandler}/>
                            </div>
                        </div>

                    </AccordionTab>


                    <AccordionTab header="Tipos">
                        <ItemEditView id={"restauranteTipoRestaurante"}
                                      title={"Tipos"}
                                      sortFieldPadrao={"tipoRestaurante.nomeAtividade"}
                                      adicionarFunction={adicionarRestauranteTipos}
                                      editarItemFunction={editarRestauranteTipos}
                                      removerItemFunction={removerRestauranteTipos}
                                      itens={entidade.tiposRestaurantes}
                                      colunas={[
                                          ItemEditViewColuna.of("tipoRestaurante.nomeAtividade", "Tipo"),
                                          ItemEditViewColuna.of("status", "Ativo", statusRestauranteTipoTemplate)
                                      ]}>
                            <Combo id={"tipoRestaurante"} label={"* Tipo"}
                                   optionLabel={"nomeAtividade"}
                                   placeHolder={"Selecione o tipo"}
                                   onChangeHandler={restauranteTipoOnChangeHandler}
                                   value={restauranteTipo.tipoRestaurante} values={tiposRestaurantes}/>

                            <Switch checked={statusRestauranteTipo} id={"status"} label={statusRestauranteTipo ? "Ativo" : "Inativo"}
                                    onChangeHandler={switchOnChangeHandler}/>
                        </ItemEditView>
                    </AccordionTab>

                    <AccordionTab header="Diferenciais">
                        <ItemEditView id={"restauranteDiferencial"}
                                      title={"Diferenciais"} key={"diferenciaisKey"}
                                      adicionarFunction={adicionarRestauranteDiferenciais}
                                      editarItemFunction={editarRestauranteDiferenciais}
                                      removerItemFunction={removerRestauranteDiferenciais}
                                      itens={entidade.diferenciais}
                                      colunas={[
                                          ItemEditViewColuna.of("diferencial.nomeDiferencial", "Diferencial"),
                                          ItemEditViewColuna.of("status", "Ativo", statusRestauranteDiferencialTemplate)
                                      ]}>
                            <Combo id={"diferencial"} label={"* Diferencial"}
                                   optionLabel={"nomeDiferencial"}
                                   placeHolder={"Selecione um diferencial"}
                                   onChangeHandler={restauranteDiferencialOnChangeHandler}
                                   value={restauranteDiferencial.diferencial} values={diferenciais}/>

                            <Texto id={"observacao"} label={"Detalhes"} value={restauranteDiferencial.observacao}
                                   maxLength={50}
                                   placeholder={"Ex: Precisa reservar com antecedência"}
                                   onChangeHandler={restauranteDiferencialOnChangeHandler}/>

                            <Switch checked={statusRestauranteDiferencial} id={"status"} label={statusRestauranteDiferencial ? "Ativo" : "Inativo"}
                                    onChangeHandler={switchDiferencialOnChangeHandler}/>
                        </ItemEditView>
                    </AccordionTab>

                    <AccordionTab header="Horário de funcionamento">
                        <Card title={"Dias / Horários de funcionamento"}>
                            <div className={styles.textoExplicativo}>Adicione os horários <b>somente em dias de funcionamento</b>, com seus respectivos intervalos, conforme exemplo:
                                <br />Hora inicial: 08:00  Hora final: 12:00
                                <br />Hora inicial: 14:00  Hora final: 18:00
                            </div>

                            <br />

                            {entidade.horariosFuncionamento ?
                                (entidade.horariosFuncionamento.map((horarioFuncionamento, horarioFuncionamentoIndex) => (
                                    <>
                                        <div key={horarioFuncionamentoIndex} className={styles.componentePaiHorarioFuncionamento}>
                                            <div className={styles.componentePaiHorarioFuncionamento}>
                                                {horarioFuncionamento.diaSemana}
                                            </div>

                                            {horarioFuncionamento.periodos !== undefined && horarioFuncionamento.periodos.length > 0 &&
                                                (horarioFuncionamento.periodos.map((periodo, periodoIndex) => (
                                                    <>
                                                        <div>
                                                            <InputMask id={"horaInicio"} mask={"99:99"} placeholder={"Hora inicial"}
                                                                       width={"100px"}
                                                                       value={periodo.horaInicio}
                                                                       onChange={(e: any) => periodoFuncionamentoChangeHandler(e, horarioFuncionamentoIndex, periodoIndex)}
                                                                       onBlur={() => validarHorarioFuncionamentoOnBlurHandler(periodo.horaInicio, periodo.horaFim)}
                                                                       className={styles.componentesHorarioFuncionamento} />
                                                            <InputMask id={"horaFim"} mask={"99:99"} placeholder={"Hora final"}
                                                                       width={"100px"}
                                                                       value={periodo.horaFim}
                                                                       onChange={(e: any) => periodoFuncionamentoChangeHandler(e, horarioFuncionamentoIndex, periodoIndex)}
                                                                       onBlur={() => validarHorarioFuncionamentoOnBlurHandler(periodo.horaInicio, periodo.horaFim)}
                                                                       className={styles.componentesHorarioFuncionamento} />

                                                            <Button icon={PrimeIcons.TRASH} label={"Limpar"} severity={"danger"} className={styles.componentesHorarioFuncionamento}
                                                                onClick={() => removerPeriodoFuncionamentoHandler(horarioFuncionamento, horarioFuncionamentoIndex, periodoIndex)} />
                                                        </div>
                                                    </>
                                            )))}

                                            <div>
                                                <Button icon={PrimeIcons.PLUS} label={"Adicionar novo horário"}
                                                        onClick={() => adicionarPeriodoFuncionamentoHandler(horarioFuncionamento, horarioFuncionamentoIndex)} />
                                            </div>
                                        </div>
                                    </>
                                ))) : (<></>)
                            }
                        </Card>
                    </AccordionTab>

                    <AccordionTab header="Horários em datas especiais">
                        <ItemEditView id={"restauranteHorarioFuncionamentoDiferencial"}
                                      title={"Horários em datas especiais"}
                                      adicionarFunction={adicionarRestauranteDiaDiferenciais}
                                      editarItemFunction={editarRestauranteDiaDiferenciais}
                                      removerItemFunction={removerRestauranteDiaDiferenciais}
                                      itens={entidade.horariosFuncionamentoDiferencial}
                                      colunas={[
                                          ItemEditViewColuna.of("data", "Data", dataRestauranteDiaDiferencialTemplate),
                                          ItemEditViewColuna.of("status", "Aberto", statusRestauranteDiaDiferencialTemplate),
                                          ItemEditViewColuna.of("horaInicio", "Hora inicial"),
                                          ItemEditViewColuna.of("horaFim", "Hora final"),
                                      ]}>

                            <div className={styles.textoExplicativo}>Adicione os horários <b>somente em datas de funcionamento diferenciadas.</b></div>

                            <br />
                            <br />

                            <div className="formgrid grid">
                                <div className="field col">
                                    <Calendario id={"data"} label={"* Data"} value={restauranteDiaDiferencial.data}
                                                fullSize={true}
                                                onChangeHandler={restauranteDiaDiferencialOnChangeHandler} />
                                </div>
                                <div className="field col">
                                    <TextoMascara id={"horaInicio"} label={"* Hora inicial"} value={restauranteDiaDiferencial.horaInicio}
                                                  mask={"99:99"} fullSize={true}
                                                  onChangeHandler={restauranteDiaDiferencialOnChangeHandler} />
                                </div>
                                <div className="field col">
                                    <TextoMascara id={"horaFim"} label={"* Hora final"} value={restauranteDiaDiferencial.horaFim}
                                                  mask={"99:99"} fullSize={true}
                                                  onChangeHandler={restauranteDiaDiferencialOnChangeHandler} />
                                </div>
                            </div>

                            <Switch checked={statusRestauranteDiaDiferencial} id={"status"} label={statusRestauranteDiaDiferencial ? "Aberto" : "Fechado"}
                                    onChangeHandler={switchDiaDiferencialOnChangeHandler}/>
                        </ItemEditView>
                    </AccordionTab>
                </Accordion>
            </EditView>
        </>
    );
}

export default RestauranteEdit;