import {TipoUsuario} from "./TipoUsuario";

export class AutenticacaoToken {

    token?: string;
    usuarioId?: number;
    usuarioEmail?: string;
    tipo?: TipoUsuario;
    mensagem?: string;
    salvarSenha?: boolean;

}