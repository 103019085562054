import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {FileUpload, FileUploadErrorEvent} from "primereact/fileupload";
import {useParams} from "react-router-dom";
import {Ajax} from "../../utils/Ajax";
import {Image} from "primereact/image";
import CustomView from "../../component/CustomView";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";

import styles from './Restaurante.module.css';
import semImagem from '../../assets/semimagem.png';

import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {MensagemContext} from "../../context/MensagemContext";
import {MensagemContextType} from "../../context/MensagemContextType";
import RestauranteCardapioItem from "../../model/RestauranteCardapioItem";
import {Card} from "primereact/card";
import RestauranteCardapioComidaItem from "../../model/RestauranteCardapioComidaItem";
import rest from "../../utils/EnvConfig";
import RestauranteCardapioComida from "../../model/RestauranteCardapioComida";
import Texto from "../../component/form/texto/Texto";

function RestauranteCardapioComidaImagemEdit() {
    const {id} = useParams();
    const {info, error} = useContext(MensagemContext) as MensagemContextType;
    const [cardapioComida, setCardapioComida] = useState<RestauranteCardapioComida>(new RestauranteCardapioComida());
    const [pesquisa, setPesquisa] = useState<string>("");
    const recursoRestauranteCompletoRest = `${rest.restauranteCardapioComida}/${rest.obterPorId}/`;
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        Ajax.of().obterPorIdCustom(recursoRestauranteCompletoRest, Number(id), (data: RestauranteCardapioComida) => {
            setCardapioComida(data);
        });
    }, [])

    const onUpload = () => {
        Ajax.of().obterPorIdCustom(recursoRestauranteCompletoRest, Number(id), (data: RestauranteCardapioComida) => {
            setCardapioComida(data);
        });
    };

    const onUploadError = (event: FileUploadErrorEvent) => {
        console.log(event);
    }

    const titulo = (): string => {
        if (cardapioComida) {
            return `Cardápio: ${cardapioComida.nomeCardapio}`;
        }
        return "";
    }

    const confirmarRegistro = async (rowData: RestauranteCardapioComidaItem) => {
        setLoading(true);

        await Ajax.of()
            .error(error)
            .info(info)
            .removerRegistroCustom(`${rest.restauranteCardapioComida}${rest.restauranteCardapioImagemRemover}${rowData.id}`, (data) => {
                if (cardapioComida && cardapioComida.itens) {
                    rowData.imagem = undefined;
                    setCardapioComida({...cardapioComida, itens: [...cardapioComida.itens.filter(c => c.id !== rowData.id), rowData]});
                }
                setLoading(false);
            }, () => setLoading(false));
    };

    const removerRegistroConfirm = async (rowData: RestauranteCardapioItem) => {
        confirmDialog({
            message: 'Tem certeza que deseja remover essa imagem?',
            header: 'Atenção',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            acceptLabel: "Sim",
            rejectLabel: "Não",
            async accept() {
                await confirmarRegistro(rowData);
            }
        });
    };

    const onPesquisar = (e: any) => {
        setPesquisa(e.target.value);
    }

    return (
        <>
            <CustomView id={"restaurante/cardapio/comida"} mostrarBarraBotoes={true} title={titulo()}>

                <div className={`${styles.espacamento} p-fluid grid`}>
                    <div className={"field col-12 md:col-8"}>
                        <div className={styles.textoExplicativo}>Insira somente uma foto para cada item do cardápio.</div>
                    </div>
                    <div className={"field col-12 md:col-4"}>
                        <Texto id={"pesquisa"} label={"Pesquisa"}
                               fullSize={true} placeholder={"Ex: massas"}
                               value={pesquisa} onChangeHandler={onPesquisar}/>
                    </div>
                </div>

                <div className="flex grid">
                    {cardapioComida && cardapioComida.itens && cardapioComida.itens.length > 0 ?
                        cardapioComida.itens
                            .filter(item => pesquisa === ""
                                        || item.nome?.toLowerCase().includes(pesquisa.toLowerCase())
                                        || item.grupo?.nome?.toLowerCase().includes(pesquisa.toLowerCase()))
                            .map(cardapio =>
                            (
                                <div key={cardapio.id} className={"col-4"}>
                                    <Card title={`${cardapio.grupo?.nome} - ${cardapio.nome}`}
                                          className={styles.cardImagemCardapio}>
                                        <div className={"col"}>
                                            {cardapio.imagem ?
                                                (
                                                    <center>
                                                        <Image
                                                            src={`${rest.server}${rest.restauranteCardapioComida}${rest.restauranteCardapioImagemObterPorId}${cardapio.id}`}
                                                            className={"p-2"} preview={true} loading={"lazy"}
                                                            alt="Image" width="250" height="140"/>
                                                    </center>
                                                ) : (
                                                    <div>
                                                        <center><img src={semImagem} alt={"Sem imagem"}
                                                                     width="150" height="140"/></center>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className={`col-12 ${styles.botaoRemoverImagemAlinhamento}`}>
                                            <FileUpload mode="basic" multiple={false} name="fotos"
                                                        className={styles.botaoRemoverImagemCardapio}
                                                        url={`${rest.server}${rest.restauranteCardapioComida}${rest.restauranteCardapioImagemUpload}${cardapio.id}`}
                                                        onUpload={onUpload} onError={onUploadError}
                                                        accept="image/*" maxFileSize={5242880} auto
                                                        chooseLabel="Adicionar foto"/>

                                            <Button label={"Remover"} icon={PrimeIcons.TRASH}
                                                    severity={"danger"}
                                                    loading={loading}
                                                    onClick={async () => await removerRegistroConfirm(cardapio)}
                                                    className={styles.botaoRemoverImagemCardapio}/>
                                        </div>
                                    </Card>
                                </div>
                            )
                        ) : (<div><h3>Nenhuma imagem cadastrada</h3></div>)
                    }
                </div>
                <ConfirmDialog/>
            </CustomView>
        </>
);
}

export default RestauranteCardapioComidaImagemEdit;