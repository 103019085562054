import * as React from "react";
import {forwardRef} from "react";

import styles from './Telefone.module.css';
import TelefoneProps from './TelefoneProps';
import TextoMascara from "../mascara/TextoMascara";

const Telefone = forwardRef((props: TelefoneProps, inputRef?: any) => {
    return (
        <>
            <TextoMascara id={props.id} value={props.value}
                          label={props.label}
                          ref={inputRef}
                          tooltip={props.tooltip}
                          placeholder={props.placeholder}
                          tooltipPosition={props.tooltipPosition}
                          size={props.size}
                          required={props.required}
                          mask={"(99) 9999-9999"}
                          maxLength={props.maxLength}
                          onBlurHandler={props.onBlurHandler}
                          onFocusHandler={props.onFocusHandler}
                          onChangeHandler={props.onChangeHandler}/>
        </>
    );
});

export default Telefone;