import * as React from "react";
import {forwardRef} from "react";

import styles from './MultiCombo.module.css';
import MultiComboProps from './MultiComboProps';
import {MultiSelect} from "primereact/multiselect";

const MultiCombo = forwardRef((props: MultiComboProps, inputRef?: any) => {
    return (
        <>
            <div className={`${styles.espacamento} p-fluid grid`}>
                <div className="field col-12 md:col-4">
                    <label className={styles.label} htmlFor={props.id}>{props.label}</label>
                    <span>
                        <MultiSelect id={props.id}
                            value={props.value}
                             onChange={props.onChangeHandler}
                             options={props.values}
                             optionLabel={props.optionLabel}
                             optionValue={props.optionValue}
                             filter={props.filter}
                             placeholder={props.placeholder}
                             maxSelectedLabels={3} />
                    </span>
                </div>
            </div>
        </>
    );
});

export default MultiCombo;