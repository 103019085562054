import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import {MensagemContext} from "../../context/MensagemContext";
import {MensagemContextType} from "../../context/MensagemContextType";
import Restaurante from "../../model/Restaurante";
import rest from "../../utils/EnvConfig";
import {ItemEditViewColuna} from "../../component/ItemEditViewColuna";
import Texto from "../../component/form/texto/Texto";
import MoedaBancario from "../../component/form/moeda-bancario/MoedaBancario";
import Switch from "../../component/form/switch/Switch";
import ItemEditView from "../../component/ItemEditView";
import RestauranteCardapioBebidaItem from "../../model/RestauranteCardapioBebidaItem";
import DinheiroUtils from "../../utils/DinheiroUtils";
import ItemEditState from "../../component/ItemEditState";
import EditView from "../../component/EditView";
import RestauranteCardapioBebida from "../../model/RestauranteCardapioBebida";
import {Ajax} from "../../utils/Ajax";
import {TipoUsuario} from "../../model/TipoUsuario";
import useToken from "../../hook/useToken";
import MultiCombo from "../../component/form/multi-combo/MultiCombo";
import GrupoBebidaSearch from "../../component/form/grupo/GrupoBebidaSearch";

function RestauranteCardapioBebidaEdit() {
    const {token} = useToken();
    const itensCardapioTitleRef = useRef<any>();
    const {info, error} = useContext(MensagemContext) as MensagemContextType;
    const [itemEditState] = useState<ItemEditState>(new ItemEditState());
    const [cardapio, setCardapio] = useState<RestauranteCardapioBebida>(new RestauranteCardapioBebida());
    const [cardapioItem, setCardapioItem] = useState<RestauranteCardapioBebidaItem>(new RestauranteCardapioBebidaItem());
    const [restaurantes, setRestaurantes] = useState<Restaurante[]>([]);
    const [statusCardapioBebida, setStatusCardapioBebida] = useState(cardapioItem.status ? cardapioItem.status : true);

    useEffect(() => {
        Ajax.of()
            .error(error)
            .obterTodosCustom(obterRecursoRestaurantesCorreto(), (data) => {
                setRestaurantes(data);
            });
    }, []);

    const obterRecursoRestaurantesCorreto = (): string | undefined => {
        if (token && token.tipo === TipoUsuario.ADMINISTRADOR) {
            return `${rest.restaurante}/${rest.obterTodos}`;
        }

        if (token && token.tipo === TipoUsuario.CLIENTE) {
            return `${rest.restaurante}/${rest.restaurantesPorUsuario}${token.usuarioId}`;
        }

        return "";
    }

    const cardapioOnChangeHandler = (e: any) => {
        setCardapio({ ...cardapio, [e.target.id]: e.target.value });
    }

    const validarRestauranteCardapioBebidas = (onSuccess: () => void) => {
        if (!cardapioItem.grupo) {
            error("Grupo é obrigatório");
            return;
        }

        if (!cardapioItem.nome) {
            error("Nome do prato é obrigatório");
            return;
        }

        if (!cardapioItem.unidadeMedida) {
            error("Unidade de medida é obrigatório");
            return;
        }

        if (!cardapioItem.valor) {
            error("Valor do prato é obrigatório");
            return;
        }

        onSuccess();
    }

    const adicionarRestauranteCardapioBebidas = () => {
        validarRestauranteCardapioBebidas(() => {
            if (cardapio.itens !== undefined) {
                // TODO Gambiarra, verificar uma forma melhor pra esconder o placeholder para não gerar confusão para o usuário
                if (cardapioItem.observacao && cardapioItem.observacao === " ") {
                    cardapioItem.observacao = cardapioItem.observacao.trim();
                }

                setCardapio({...cardapio, itens: [...cardapio.itens, cardapioItem]});
                setCardapioItem(new RestauranteCardapioBebidaItem());
                setStatusCardapioBebida(true);

                itemEditState.limparState();

                scrollItensCardapioTitle();
            }
        });
    }

    const editarRestauranteCardapioBebidas = (item: RestauranteCardapioBebidaItem) => {
        if (itemEditState.state) {
            error(`Já existe um item sendo editado em ${itemEditState.nomeSessaoItem}`);
            return;
        }

        itemEditState.buildItemState("Cardápio - Bebida", item);

        // TODO Gambiarra, verificar uma forma melhor pra esconder o placeholder para não gerar confusão para o usuário
        if (!item.observacao) {
            item.observacao = " ";
        }

        scrollItensCardapioTitle();

        setCardapioItem(item);
        if (cardapio.itens !== undefined) {
            setCardapio({ ...cardapio, itens: [...cardapio.itens.filter(t => t !== item)] });
        }
    }

    const removerRestauranteCardapioBebidas = (item: RestauranteCardapioBebidaItem) => {
        if (cardapio.itens !== undefined) {
            setCardapio({ ...cardapio, itens: [...cardapio.itens.filter(t => t !== item)] });
        }
    }

    const switchCardapioBebidaOnChangeHandler = (e: any) => {
        setStatusCardapioBebida(e.value);
        setCardapioItem({ ...cardapioItem, status: e.value });
    }

    const cardapioBebidaOnChangeHandler = (e: any) => {
        setCardapioItem({ ...cardapioItem, [e.target.id]: e.target.value });
    }

    const cardapioBebidaMoedaOnChangeHandler = (value: number, valueCorrigido?: number) => {
        setCardapioItem({ ...cardapioItem, valor: valueCorrigido });
    }

    const valorRestauranteCardapioBebidaTemplate = (item: RestauranteCardapioBebidaItem) => {
        if (item.valor) {
            return DinheiroUtils.formatToDinheiro(item.valor);
        }
        return "R$ 0,00";
    };

    const statusRestauranteCardapioBebidaTemplate = (item: RestauranteCardapioBebidaItem) => {
        return item.status ? "Sim" : "Não";
    };
    // ------

    const editEntidade = (data: RestauranteCardapioBebida) => {
        setCardapio(data);
    }

    const validar = async (): Promise<boolean> => {
        if (itemEditState.state) {
            error(`Existe um item sendo editado em ${itemEditState.nomeSessaoItem}`);
            return false;
        }

        if (!cardapio.nomeCardapio) {
            error(`Nome do cardápio é obrigatório`);
            return false;
        }

        if (!cardapio.restaurantes || cardapio.restaurantes.length === 0) {
            error(`Selecione ao menos um restaurante`);
            return false;
        }

        if (cardapio.itens) {
            const itensSemGrupo = cardapio.itens.filter(i => !i.grupo).length;
            if (itensSemGrupo > 0) {
                error(`Existem itens do cardápio sem grupo`);
                return false;
            }
        }

        return true;
    };

    const scrollItensCardapioTitle = () => {
        if (itensCardapioTitleRef.current) {
            itensCardapioTitleRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    return (
        <>
            <EditView title={"Restaurante - Cardápio de bebida"}
                      id={`${rest.restauranteCardapioBebida}`}
                      entidade={cardapio}
                      onValidationHandler={validar}
                      entidadeUseStateFunction={editEntidade}>

                <Texto id={"nomeCardapio"} label={"* Nome do cardápio"} value={cardapio.nomeCardapio} maxLength={20}
                       placeholder={"Ex: Cardápio geral"}
                       size={"100%"}
                       onChangeHandler={cardapioOnChangeHandler}/>

                <MultiCombo values={restaurantes} id={"restaurantes"}
                            label={"Selecione os restaurantes"} value={cardapio.restaurantes}
                            optionLabel={"nomeEstabelecimento"}
                            onChangeHandler={cardapioOnChangeHandler}/>

                <div ref={itensCardapioTitleRef}></div>
                <ItemEditView id={"restauranteCardapioBebida"}
                              title={"Itens do cardápio"}
                              adicionarFunction={adicionarRestauranteCardapioBebidas}
                              editarItemFunction={editarRestauranteCardapioBebidas}
                              removerItemFunction={removerRestauranteCardapioBebidas}
                              itens={cardapio.itens}
                              colunas={[
                                  ItemEditViewColuna.of("grupo.nome", "Grupo"),
                                  ItemEditViewColuna.of("nome", "Nome da bebida"),
                                  ItemEditViewColuna.of("unidadeMedida", "Tamanho"),
                                  ItemEditViewColuna.of("valor", "Valor", valorRestauranteCardapioBebidaTemplate),
                                  ItemEditViewColuna.of("status", "Ativo", statusRestauranteCardapioBebidaTemplate)
                              ]}>

                    <div className="formgrid grid">
                        <div className="field col">
                            <GrupoBebidaSearch id={"grupo"} label={"* Grupo"} value={cardapioItem.grupo}
                                               placeholder={"Ex: Sucos"}
                                               fullSize={true} cardapio={cardapio}
                                               maxLength={20}
                                               onChangeHandler={cardapioBebidaOnChangeHandler}
                            />
                        </div>
                        <div className="field col">
                            <Texto id={"nome"} label={"* Nome da bebida"} value={cardapioItem.nome}
                                   maxLength={20} fullSize={true}
                                   placeholder={"Ex: Suco de abacaxi com hortelã"}
                                   onChangeHandler={cardapioBebidaOnChangeHandler}/>
                        </div>
                    </div>

                    <div className="formgrid grid">
                        <div className="field col">
                            <Texto id={"unidadeMedida"} label={"* Tamanho"} value={cardapioItem.unidadeMedida}
                                   maxLength={20} fullSize={true}
                                   placeholder={"Ex: Pequeno"}
                                   onChangeHandler={cardapioBebidaOnChangeHandler}/>
                        </div>
                        <div className="field col">
                            <MoedaBancario id={"valor"} label={"* Valor"} value={cardapioItem.valor}
                                           maxLength={999999} fullSize={true}
                                           onValueChangeHandler={cardapioBebidaMoedaOnChangeHandler}/>
                        </div>
                    </div>

                    <div className="formgrid grid">
                        <div className="field col">
                            <Texto id={"observacao"} label={"Detalhes"} value={cardapioItem.observacao}
                                   maxLength={100} fullSize={true}
                                   placeholder={"Ex: Suco natural"}
                                   onChangeHandler={cardapioBebidaOnChangeHandler}/>
                        </div>
                        <div className="field col">
                        </div>
                    </div>

                    <Switch checked={statusCardapioBebida} id={"status"}
                            label={statusCardapioBebida ? "Ativo" : "Inativo"}
                            onChangeHandler={switchCardapioBebidaOnChangeHandler}/>

                </ItemEditView>
            </EditView>
        </>
    );
}

export default RestauranteCardapioBebidaEdit;