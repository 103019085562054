
export default class DinheiroUtils {

    private constructor() {
    }

    public static formatToDinheiroParaCurrencyInput(numero: number): string {
        return (numero / 100).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
    }

    public static formatToDinheiro(numero: number): string {
        return (numero).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
    }

    public static formatFromDinheiro(numero: string): number {
        return Number(numero.replaceAll(".", "")
            .replaceAll(",", ".").replaceAll("R", "")
            .replaceAll("$", "").trimStart().trimEnd());
    }

}