import * as React from "react";

import styles from './Conteudo.module.css';

import { Panel } from 'primereact/panel';

function Conteudo(props: any) {
    return (
        <div className={styles.conteudo}>
            <Panel>
                {props.children}
            </Panel>
        </div>
    );
}

export default Conteudo;