import {Entidade} from "./Entidade";

export default abstract class RestauranteCardapioItem extends Entidade {

    nome?: string;
    unidadeMedida?: string;
    valor?: number;
    imagem?: string;
    observacao?: string;
    status: boolean = true;

}