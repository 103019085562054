import React, {useContext, useEffect, useRef, useState} from "react";

import EditView from "../../component/EditView";
import Texto from "../../component/form/texto/Texto";

import TipoServico from "../../model/TipoServico";
import {MensagemContext} from "../../context/MensagemContext";
import {MensagemContextType} from "../../context/MensagemContextType";
import FormUtils from "../../utils/FormUtils";
import {TooltipPosition} from "../../component/form/TooltipPosition";
import useToken from "../../hook/useToken";
import {useNavigate} from "react-router-dom";
import {TipoUsuario} from "../../model/TipoUsuario";

function TipoServicoEdit() {
    const { error } = useContext(MensagemContext) as MensagemContextType;
    const [entidade, setEntidade] = useState<TipoServico>({});
    const {token} = useToken();
    const navigate = useNavigate();

    useEffect(() => {
        if (token && token.tipo !== TipoUsuario.ADMINISTRADOR) {
            navigate("/sem-permissao");
            return;
        }
    }, []);

    const editEntidade = (data: TipoServico) => {
        setEntidade(data);
    }

    const entidadeOnChangeHandler = (e: any) => {
        setEntidade({ ...entidade, [e.target.id]: e.target.value });
    }

    const validar = async (): Promise<boolean> => {
        if (!entidade.nomeServico) {
            FormUtils.invalidarComponente(error, "Descrição é obrigatório.");
            return false;
        }

        return true;
    }

    return (
        <>
            {token && token.tipo === TipoUsuario.ADMINISTRADOR && (
                <EditView title={ "Tipo serviço" }
                          id={`${process.env.REACT_APP_REST_TIPO_SERVICO}`}
                          entidade={ entidade }
                          onValidationHandler={ validar }
                          entidadeUseStateFunction={ editEntidade }>

                    <Texto id={ "nomeServico" } value={ entidade.nomeServico }
                           label={ "* Descrição" } size={ "100%" } maxLength={ 20 }
                           tooltip={"Informe a descrição"} tooltipPosition={TooltipPosition.TOP}
                           onChangeHandler={entidadeOnChangeHandler} />

                </EditView>
            )}
        </>
    );
}

export default TipoServicoEdit;