
export enum DiaSemana {

    SEGUNDA = "Segunda-feira",
    TERCA = "Terça-feira",
    QUARTA = "Quarta-feira",
    QUINTA = "Quinta-feira",
    SEXTA = "Sexta-feira",
    SABADO = "Sábado",
    DOMINGO = "Domingo"

}