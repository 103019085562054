import {AutenticacaoToken} from "../model/AutenticacaoToken";
import rest from "./EnvConfig";

export class Ajax {

    private infoFunction: (mensagem: string) => void | undefined;
    private errorFunction: (mensagem: string) => void | undefined;

    private readonly headersConfig = (token: AutenticacaoToken): {} => {
        return {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token.token}`
        }
    };

    private constructor(info: (mensagem: string) => void | undefined, error: (mensagem: string) => void) {
        this.errorFunction = error;
        this.infoFunction = info;
    }

    public static of (): Ajax {
        return new Ajax(() => {}, () => {});
    }

    public info(infoFunction: (mensagem: string) => void | undefined): Ajax {
        this.infoFunction = infoFunction;
        return this;
    }

    public error(errorFunction: (mensagem: string) => void): Ajax {
        this.errorFunction = errorFunction;
        return this;
    }

    public async obterTodos(idRest: string | undefined, onSuccess?: (data: any[]) => void, onError?: (error: any) => void): Promise<void> {
        const config = this.headersConfig(this.getToken());
        
        await fetch(`${rest.server}${idRest}/${rest.obterTodos}`, {
            method: 'GET',
            headers: config
        })
            .then(resp => resp.json())
            .then(data => {
                if (onSuccess !== undefined) onSuccess(data);
            })
            .catch(err => {
                console.log(err);
                if (this.errorFunction !== undefined) {
                    this.errorFunction("Houve um problema para obter os registros.");
                }
                if (onError !== undefined) onError(err);
            });
    }

    public async obterTodosCustom(idRest: string | undefined, onSuccess?: (data: any[]) => void, onError?: (error: any) => void): Promise<void> {
        const config = this.headersConfig(this.getToken());
        await fetch(`${rest.server}${idRest}`, {
            method: 'GET',
            headers: config
        })
            .then(resp => resp.json())
            .then(data => {
                if (onSuccess !== undefined) onSuccess(data);
            })
            .catch(err => {
                console.log(err);
                if (this.errorFunction !== undefined) {
                    this.errorFunction("Houve um problema para obter os registros.");
                }
                if (onError !== undefined) onError(err);
            });
    }

    public async obterPorId(idRest: string | undefined, idEntidade: number,
                            onSuccess?: (data: any) => void,
                            onError?: (error: any) => void): Promise<void> {
        const config = this.headersConfig(this.getToken());
        await fetch(`${rest.server}${idRest}/${rest.obterPorId}/${idEntidade}`, {
            method: 'GET',
            headers: config
        })
        .then(resp => resp.json())
        .then(data => {
            if (onSuccess !== undefined) onSuccess(data);
        })
        .catch(err => {
            console.log(err);
            if (this.errorFunction !== undefined) {
                this.errorFunction("Houve um problema para obter o registro.");
            }
            if (onError !== undefined) onError(err);
        });
    }

    public async obterPorIdCustom(idRest: string | undefined, idEntidade: number,
                            onSuccess?: (data: any) => void,
                            onError?: (error: any) => void): Promise<void> {
        const config = this.headersConfig(this.getToken());
        await fetch(`${rest.server}${idRest}${idEntidade}`, {
            method: 'GET',
            headers: config
        })
            .then(resp => resp.json())
            .then(data => {
                if (onSuccess !== undefined) onSuccess(data);
            })
            .catch(err => {
                console.log(err);
                if (this.errorFunction !== undefined) {
                    this.errorFunction("Houve um problema para obter o registro.");
                }
                if (onError !== undefined) onError(err);
            });
    }

    public async obterPorFiltro(idRest: string | undefined, filtro: {},
                                onSuccess?: (data: any[]) => void,
                                onError?: (error: any) => void): Promise<void> {
        const config = this.headersConfig(this.getToken());
        await fetch(`${rest.server}${idRest}/${rest.pesquisar}`, {
            method: "POST",
            headers: config,
            body: JSON.stringify(filtro)
        })
        .then(resp => resp.json())
        .then((data: any[]) => {
            if (onSuccess !== undefined) onSuccess(data);
        })
        .catch((err) => {
            console.log(err);
            if (this.errorFunction !== undefined) {
                this.errorFunction("Houve um problema para obter os registros filtrados.");
            }
            if (onError !== undefined) onError(err);
        });
    }

    public async salvarRegistro(idRest: string | undefined, entidade: any,
                                 onSuccess?: (data: any[]) => void,
                                 onError?: (error: any) => void): Promise<void> {
        const config = this.headersConfig(this.getToken());
        await fetch(`${rest.server}${idRest}/${rest.salvar}`, {
            method: "POST",
            headers: config,
            body: JSON.stringify(entidade)
        })
            .then(async resp => {
                if (resp.ok) {
                    return resp.json();
                }
                throw new Error(await resp.text());
            })
            .then((data: any) => {
                if (onSuccess !== undefined) onSuccess([]);
                if (this.infoFunction !== undefined) {
                    this.infoFunction("Registro salvo com sucesso.");
                }
            })
            .catch((err: Error) => {
                if (this.errorFunction !== undefined) {
                    this.errorFunction(`Houve um problema para salvar o registro: ${err.message}`);
                }
                if (onError !== undefined) onError(err);
            });
    }

    public async postRegistroCustom(idRest: string | undefined, entidade: any,
                                onSuccess?: (data?: any) => void,
                                onError?: (error?: any) => void): Promise<void> {
        const config = this.headersConfig(this.getToken());
        await fetch(`${idRest}`, {
            method: "POST",
            headers: config,
            body: JSON.stringify(entidade)
        })
            .then(async resp => {
                if (resp.ok) {
                    return resp.json();
                }
                throw new Error(await resp.text());
            })
            .then((data: any) => {
                if (onSuccess !== undefined) onSuccess(data);
                if (this.infoFunction !== undefined) {
                    this.infoFunction("Registro salvo com sucesso.");
                }
            })
            .catch((err: Error) => {
                if (this.errorFunction !== undefined) {
                    this.errorFunction(`Houve um problema para salvar o registro: ${err.message}`);
                }
                if (onError !== undefined) onError(err);
            });
    }

    public async removerRegistro(idRest: string | undefined, idEntidade: number,
                                 onSuccess?: (data: any[]) => void,
                                 onError?: (error: any) => void): Promise<void> {
        const config = this.headersConfig(this.getToken());
        await fetch(`${rest.server}${idRest}/${rest.remover}/${idEntidade}`, {
            method: 'DELETE',
            headers: config
        })
        .then(async resp => {
            if (resp.ok) {
                return resp.json();
            }
            throw new Error(await resp.text());
        })
        .then(() => {
            if (onSuccess !== undefined) onSuccess([]);

            if (this.infoFunction !== undefined) {
                this.infoFunction("Registro removido com sucesso.");
            }
        })
        .catch((err: Error) => {
            if (this.errorFunction !== undefined) {
                this.errorFunction(`Houve um problema para remover o registro: ${err.message}`);
            }
            if (onError !== undefined) onError(err);
        });
    }

    public async removerRegistroCustom(idRest: string | undefined,
                                 onSuccess?: (data: any[]) => void,
                                 onError?: (error: any) => void): Promise<void> {
        const config = this.headersConfig(this.getToken());
        await fetch(`${rest.server}${idRest}`, {
            method: 'DELETE',
            headers: config
        })
            .then(async resp => {
                if (resp.ok) {
                    return resp.json();
                }
                throw new Error(await resp.text());
            })
            .then(() => {
                if (onSuccess !== undefined) onSuccess([]);

                if (this.infoFunction !== undefined) {
                    this.infoFunction("Registro removido com sucesso.");
                }
            })
            .catch((err: any) => {
                if (this.errorFunction !== undefined) {
                    this.errorFunction(`Houve um problema para remover o registro: ${err.message}`);
                }
                if (onError !== undefined) onError(err);
            });
    }

    private getToken = (): AutenticacaoToken => {
        const tokenString = sessionStorage.getItem(rest.autenticacaoStorage as string);
        if (tokenString) {
            return JSON.parse(tokenString) as AutenticacaoToken;
        }

        const tokenStringLocal = localStorage.getItem(rest.autenticacaoStorage as string);
        if (tokenStringLocal) {
            return JSON.parse(tokenStringLocal) as AutenticacaoToken;
        }

        return new AutenticacaoToken();
    };

}