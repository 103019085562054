import {useState} from 'react';
import {AutenticacaoToken} from "../model/AutenticacaoToken";
import rest from "../utils/EnvConfig";

export default function useToken() {
    const getToken = (): AutenticacaoToken => {
        // TODO Refatorar a verificacao do json
        const tokenString = sessionStorage.getItem(rest.autenticacaoStorage as string);
        if (tokenString && tokenString !== "{}") {
            return JSON.parse(tokenString) as AutenticacaoToken;
        }

        const tokenStringLocal = localStorage.getItem(rest.autenticacaoStorage as string);
        if (tokenStringLocal && tokenStringLocal !== "{}") {
            return JSON.parse(tokenStringLocal) as AutenticacaoToken;
        }

        return new AutenticacaoToken();
    };

    const [token, setToken] = useState<AutenticacaoToken>(getToken());

    const saveToken = (token?: AutenticacaoToken) => {
        if (!token) {
            localStorage.removeItem(rest.autenticacaoStorage as string);
            sessionStorage.removeItem(rest.autenticacaoStorage as string);
            return;
        }

        if (token.salvarSenha) {
            localStorage.setItem(rest.autenticacaoStorage as string, JSON.stringify(token));
        } else {
            sessionStorage.setItem(rest.autenticacaoStorage as string, JSON.stringify(token));
        }

        setToken(token);
    };

    return {
        setToken: saveToken,
        token
    }
}