import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {FileUpload, FileUploadErrorEvent} from "primereact/fileupload";
import {useParams} from "react-router-dom";
import {RestauranteImagem} from "../../model/RestauranteImagem";
import {Ajax} from "../../utils/Ajax";
import {Image} from "primereact/image";
import CustomView from "../../component/CustomView";
import {Divider} from "primereact/divider";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";

import styles from './Restaurante.module.css';
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {MensagemContext} from "../../context/MensagemContext";
import {MensagemContextType} from "../../context/MensagemContextType";
import Restaurante from "../../model/Restaurante";
import rest from "../../utils/EnvConfig";

function RestauranteImagemEdit() {
    const {id} = useParams();
    const {info, error} = useContext(MensagemContext) as MensagemContextType;
    const [restaurante, setRestaurante] = useState<Restaurante>(new Restaurante());
    const [restauranteImagens, setRestauranteImagens] = useState<RestauranteImagem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        Ajax.of().obterPorId(`${rest.restaurante}`, Number(id), (data: Restaurante) => setRestaurante(data));

        Ajax.of().obterTodosCustom(`${rest.restauranteImagem}${rest.restauranteImagemObterTodos}${id}`,
            (data: RestauranteImagem[]) => setRestauranteImagens(data));
    }, [])

    const onUpload = () => {
        Ajax.of().obterTodosCustom(`${rest.restauranteImagem}${rest.restauranteImagemObterTodos}${id}`,
            (data: RestauranteImagem[]) => setRestauranteImagens(data));
    };

    const onUploadError = (event: FileUploadErrorEvent) => {
        console.log(event);
    }

    const titulo = (): string => {
        if (restaurante) {
            return `${restaurante.nomeEstabelecimento}`;
        }
        return "";
    }

    const confirmarRegistro = async (rowData: RestauranteImagem) => {
        setLoading(true);

        await Ajax.of()
            .error(error)
            .info(info)
            .removerRegistroCustom(`${rest.restauranteImagem}${rest.restauranteImagemRemover}${rowData.id}`, (data) => {
                if (restauranteImagens !== undefined) {
                    setRestauranteImagens(restauranteImagens.filter(r => r.id !== rowData.id))
                }

                setLoading(false);
            }, () => setLoading(false));
    };

    const removerRegistroConfirm = async (rowData: RestauranteImagem) => {
        confirmDialog({
            message: 'Tem certeza que deseja remover essa imagem?',
            header: 'Atenção',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            acceptLabel: "Sim",
            rejectLabel: "Não",
            async accept() {
                await confirmarRegistro(rowData);
            }
        });
    };

    return (
        <>
            <CustomView id={"restaurante"} mostrarBarraBotoes={true} title={titulo()}>
                <FileUpload mode="basic" multiple={true} name="fotos"
                            url={`${rest.server}${rest.restauranteImagem}${rest.restauranteImagemUpload}${id}`}
                            onUpload={onUpload} onError={onUploadError}
                            accept="image/*" maxFileSize={5242880} auto
                            chooseLabel="Adicionar fotos"/>

                <Divider />

                <div className="flex grid">
                    {restauranteImagens && restauranteImagens.length > 0 ?
                        restauranteImagens.map(imagem =>
                            (
                                <div key={imagem.id} className={"col-3"}>
                                    <center>
                                        <div>
                                            <Image src={`${rest.server}${rest.restauranteImagem}${rest.restauranteImagemObterPorId}${imagem.id}`}
                                                   className={"p-2"} preview={true} loading={"lazy"}
                                                   alt="Image" width="250" height="140"/>
                                        </div>

                                        <div className={`col ${styles.botaoRemoverImagemRestauranteAlinhamento}`}>
                                            <Button label={"Remover"} icon={PrimeIcons.TRASH} severity={"danger"}
                                                    onClick={async () => await removerRegistroConfirm(imagem)}
                                                    className={styles.botaoRemoverImagem}/>
                                        </div>
                                    </center>
                                </div>
                            )
                        ) : (<div><h3>Nenhuma imagem cadastrada</h3></div>)
                    }
                </div>
                <ConfirmDialog />
            </CustomView>
        </>
    );
}

export default RestauranteImagemEdit;