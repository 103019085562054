export default class ItemEditState {

    private _nomeSessaoItem: string;
    private _item: any;
    private _state: boolean = false;

    constructor() {
        this._nomeSessaoItem = "";
        this._item = null;
    }

    public limparState(): void {
        this._nomeSessaoItem = "";
        this._item = null;
        this._state = false;
    }

    public buildItemState(nomeSessaoItem: string, item: any) {
        this._nomeSessaoItem = nomeSessaoItem;
        this._item = item;
        this._state = true;
    }

    get nomeSessaoItem(): string {
        return this._nomeSessaoItem;
    }

    get item(): any {
        return this._item;
    }

    get state(): boolean {
        return this._state;
    }

}