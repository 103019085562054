import * as React from "react";

import styles from './Footer.module.css';

function Footer() {
    return (
        <>
            <footer className={styles.footer}>

            </footer>
        </>
    );
}

export default Footer;