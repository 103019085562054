import ListView from "../../component/ListView";
import {Column} from "primereact/column";
import useToken from "../../hook/useToken";
import {useEffect} from "react";
import {TipoUsuario} from "../../model/TipoUsuario";
import {useNavigate} from "react-router-dom";

function TipoRestauranteList() {
    const {token} = useToken();
    const navigate = useNavigate();

    useEffect(() => {
        if (token && token.tipo !== TipoUsuario.ADMINISTRADOR) {
            navigate("/sem-permissao");
            return;
        }
    }, []);

    return (
        <>
            {token && token.tipo === TipoUsuario.ADMINISTRADOR && (
                <ListView title={"Tipo restaurante"}
                          id={`${process.env.REACT_APP_REST_TIPO_RESTAURANTE}`}>
                    <Column field="nomeAtividade" header="Descrição" sortable />
                </ListView>
            )}
        </>
    );
}

export default TipoRestauranteList;