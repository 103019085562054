import React, {useContext, useEffect, useState} from "react";

import EditView from "../../component/EditView";
import Texto from "../../component/form/texto/Texto";

import TipoRestaurante from "../../model/TipoRestaurante";
import {MensagemContext} from "../../context/MensagemContext";
import {MensagemContextType} from "../../context/MensagemContextType";
import FormUtils from "../../utils/FormUtils";
import {TooltipPosition} from "../../component/form/TooltipPosition";
import {TipoUsuario} from "../../model/TipoUsuario";
import useToken from "../../hook/useToken";
import {useNavigate} from "react-router-dom";

function TipoRestauranteEdit() {
    const {token} = useToken();
    const navigate = useNavigate();
    const {error} = useContext(MensagemContext) as MensagemContextType;
    const [entidade, setEntidade] = useState<TipoRestaurante>({});

    const editEntidade = (data: TipoRestaurante) => {
        setEntidade(data);
    }

    const entidadeOnChangeHandler = (e: any) => {
        setEntidade({...entidade, [e.target.id]: e.target.value});
    }

    const validar = async (): Promise<boolean> => {
        if (!entidade.nomeAtividade) {
            FormUtils.invalidarComponente(error, "Descrição é obrigatório.");
            return false;
        }

        return true;
    }

    useEffect(() => {
        if (token && token.tipo !== TipoUsuario.ADMINISTRADOR) {
            navigate("/sem-permissao");
            return;
        }
    }, []);

    return (
        <>
            {token && token.tipo === TipoUsuario.ADMINISTRADOR && (
                <EditView title={"Tipo restaurante"}
                          id={`${process.env.REACT_APP_REST_TIPO_RESTAURANTE}`}
                          entidade={entidade}
                          onValidationHandler={validar}
                          entidadeUseStateFunction={editEntidade}>

                    <Texto id={"nomeAtividade"} value={entidade.nomeAtividade}
                           label={"* Descrição"} size={"100%"} maxLength={20}
                           tooltip={"Informe a descrição"} tooltipPosition={TooltipPosition.TOP}
                           onChangeHandler={entidadeOnChangeHandler}/>

                </EditView>
            )}
        </>
    );
}

export default TipoRestauranteEdit;