
export enum TooltipPosition {

    TOP = "top",
    BOTTOM = "bottom",
    LEFT = "left",
    RIGHT = "right",
    MOUSE = "mouse"


}