

export enum TipoUsuario {

    ADMINISTRADOR = "ADMIN",

    CLIENTE = "CLIENTE",

    CONSUMIDOR = "CONSUMIDOR"

}