import React from "react";

export default class FormUtils {

    private constructor() {
    }

    public static invalidarComponente = (errorFuncion: (mensagem: string) => void,
                                         mensagemErro: string,
                                         componenteRef?: React.RefObject<any>
                                         ): void => {
        // TODO fazer o componente ficar com a borda vermelha
        // if (componenteRef && componenteRef.current) {
        //     errorFuncion(mensagemErro);
        // }
        errorFuncion(mensagemErro);
    }



}